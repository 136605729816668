import { STATES_LIST_SET_HEIGHT, STATES_LIST_SET_LIST, STATES_LIST_SET_SELECTED_STATE } from '../constants/actions';

export default function newsList(state = {height:0,states:[],selectedState:null}, action) {
  switch (action.type) {
    case STATES_LIST_SET_HEIGHT:
      return {...state, height: action.value};
    case STATES_LIST_SET_LIST:
      return {...state, states: action.value};
    case STATES_LIST_SET_SELECTED_STATE:
      return {...state, selectedState: action.value};
    default:
      return state;
  }
}
