import * as types from '../constants/actions';

export function show(message) {
  return {
    type: types.OVERLAY_SHOW,
    value: message
  }
}

export function hide() {
  return {
    type: types.OVERLAY_HIDE
  }
}

export function set(message) {
  return {
    type: types.OVERLAY_SET,
    value: message
  }
}
