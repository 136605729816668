//LOGIN
export const LOGIN_SET_EMAIL = 'LOGIN_SET_EMAIL';
export const LOGIN_SET_PWD = 'LOGIN_SET_PWD';
export const LOGIN_SET_PROFILE = 'LOGIN_SET_PROFILE';
export const LOGIN_CLEAN_DATA = 'LOGIN_CLEAN_DATA';
export const LOGIN_SET_DATA = 'LOGIN_SET_DATA';
export const LOGIN_SET_SELLERCODE ='LOGIN_SET_SELLERCODE';
export const LOGIN_OPEN_FORGOT_DIALOG = 'LOGIN_OPEN_FORGOT_DIALOG';
export const LOGIN_CLOSE_FORGOT_DIALOG = 'LOGIN_CLOSE_FORGOT_DIALOG';

//OVERLAY
export const OVERLAY_SHOW = 'OVERLAY_SHOW';
export const OVERLAY_HIDE = 'OVERLAY_HIDE';
export const OVERLAY_SET = 'OVERLAY_SET';

//MESSAGES
export const MESSAGE_SHOW = 'MESSAGE_SHOW';
export const MESSAGE_HIDE = 'MESSAGE_HIDE';

//CONTAINER
export const CONTAINER_TOGGLE_DRAWER = 'CONTAINER_TOGGLE_DRAWER';
export const CONTAINER_SET_CRITERIA = 'CONTAINER_SET_CRITERIA';
export const CONTAINER_TOGGLE_DIALOG = 'CONTAINER_TOGGLE_DIALOG';
export const CONTAINER_PAYMENT_PENDING = 'CONTAINER_PAYMENT_PENDING';

//CUSTOMER LIST
export const CUSTOMER_LIST_SET_HEIGHT = 'CUSTOMER_LIST_SET_HEIGHT';
export const CUSTOMER_LIST_SET_LIST = 'CUSTOMER_LIST_SET_LIST';

//HOME
export const HOME_SET_HEIGHT = 'HOME_SET_HEIGHT';

//STATES
export const STATES_LIST_SET_HEIGHT = 'STATES_LIST_SET_HEIGHT';
export const STATES_LIST_SET_LIST = 'STATES_LIST_SET_LIST';
export const STATES_LIST_SET_SELECTED_STATE = 'STATES_LIST_SET_SELECTED_STATE';
export const STATE_EDITOR_UPDATE_FIELD ='STATE_EDITOR_UPDATE_FIELD';

//COUPONS
export const COUPONS_LIST_SET_HEIGHT = 'COUPONS_LIST_SET_HEIGHT';
export const COUPONS_LIST_SET_LIST = 'COUPONS_LIST_SET_LIST';
export const COUPONS_LIST_SET_SELECTED_COUPON = 'COUPONS_LIST_SET_SELECTED_COUPON';
export const COUPONS_EDITOR_UPDATE_FIELD ='COUPONS_EDITOR_UPDATE_FIELD';

//HEALTHPLANS
export const HEALTHPLANS_LIST_SET_HEIGHT = 'HEALTHPLANS_LIST_SET_HEIGHT';
export const HEALTHPLANS_LIST_SET_LIST = 'HEALTHPLANS_LIST_SET_LIST';
export const HEALTHPLANS_LIST_SET_SELECTED_HEALTHPLAN = 'HEALTHPLANS_LIST_SET_SELECTED_HEALTHPLAN';
export const HEALTHPLANS_EDITOR_UPDATE_FIELD ='HEALTHPLANS_EDITOR_UPDATE_FIELD';

//SPECIES
export const SPECIES_LIST_SET_HEIGHT = 'SPECIES_LIST_SET_HEIGHT';
export const SPECIES_LIST_SET_LIST = 'SPECIES_LIST_SET_LIST';
export const SPECIES_LIST_SET_SELECTED_SPECIE = 'SPECIES_LIST_SET_SELECTED_SPECIE';
export const SPECIES_EDITOR_UPDATE_FIELD ='SPECIES_EDITOR_UPDATE_FIELD';

//SELLERS
export const SELLERS_LIST_SET_HEIGHT = 'SELLERS_LIST_SET_HEIGHT';
export const SELLERS_LIST_SET_LIST = 'SELLERS_LIST_SET_LIST';
export const SELLERS_LIST_SET_SELECTED_SELLER = 'SELLERS_LIST_SET_SELECTED_SELLER';
export const SELLERS_EDITOR_UPDATE_FIELD ='SELLERS_EDITOR_UPDATE_FIELD';

//CITIES
export const CITIES_LIST_SET_HEIGHT = 'CITIES_LIST_SET_HEIGHT';
export const CITIES_LIST_SET_LIST = 'CITIES_LIST_SET_LIST';
export const CITIES_LIST_SET_STATES = 'CITIES_LIST_SET_STATES';
export const CITIES_LIST_SET_SELECTED_CITY = 'CITIES_LIST_SET_SELECTED_CITY';
export const CITIES_EDITOR_UPDATE_FIELD ='CITIES_EDITOR_UPDATE_FIELD';
export const CITIES_LIST_SET_SELECTED_STATE = 'CITIES_LIST_SET_SELECTED_STATE';

//BREEDS
export const BREEDS_LIST_SET_HEIGHT = 'BREEDS_LIST_SET_HEIGHT';
export const BREEDS_LIST_SET_LIST = 'BREEDS_LIST_SET_LIST';
export const BREEDS_LIST_SET_SPECIES = 'BREEDS_LIST_SET_SPECIES';
export const BREEDS_LIST_SET_SELECTED_BREED = 'BREEDS_LIST_SET_SELECTED_BREED';
export const BREEDS_EDITOR_UPDATE_FIELD ='BREEDS_EDITOR_UPDATE_FIELD';
export const BREEDS_LIST_SET_SELECTED_SPECIE = 'BREEDS_LIST_SET_SELECTED_SPECIE';

//USERS
export const USERS_LIST_SET_HEIGHT = 'USERS_LIST_SET_HEIGHT';
export const USERS_LIST_SET_LIST = 'USERS_LIST_SET_LIST';
export const USERS_LIST_SET_SELECTED_USER = 'USERS_LIST_SET_SELECTED_USER';
export const USERS_LIST_UPDATE_FILTER = 'USERS_LIST_UPDATE_FILTER';
export const USERS_LIST_UPDATE_USER_PAYMENT = 'USERS_LIST_UPDATE_USER_PAYMENT';

//USER
export const USER_RESET = 'USER_RESET';
export const USER_SET_NAME = 'USER_SET_NAME';
export const USER_SET_LASTNAME = 'USER_SET_LASTNAME';
export const USER_SET_EMAIL = 'USER_SET_EMAIL';
export const USER_SET_CELLPHONE = 'USER_SET_CELLPHONE';
export const USER_SET_PASSWORD = 'USER_SET_PASSWORD';
export const USER_SET_REPEAT_PASSWORD = 'USER_SET_REPEAT_PASSWORD';
export const USER_SET_ID_NUMBER = 'USER_SET_ID_NUMBER';
export const USER_SET_REFERRAL_CODE = 'USER_SET_REFERRAL_CODE';
export const USER_SET_STATUS = 'USER_SET_STATUS';
export const USER_SET_TERMS_ACCEPTANCE_DATE = 'USER_SET_TERMS_ACCEPTANCE_DATE';
export const USER_SET_STATE_ID = 'USER_SET_STATE_ID';
export const USER_SET_CITY_ID = 'USER_SET_CITY_ID';
export const USER_SET_ROLE_ID = 'USER_SET_ROLE_ID';
export const USER_SET_ID = 'USER_SET_ID';
export const USER_SET_ERROR = 'USER_SET_ERROR';
export const USER_SET_STATES = 'USER_SET_STATES';
export const USER_SET_CITIES = 'USER_SET_CITIES';
export const USER_SET_SELLERS = 'USER_SET_SELLERS';
export const USER_SET_SUBSCRIPTION_METHOD = 'USER_SET_SUBSCRIPTION_METHOD';
export const USER_SET_EMAIL_MP = 'USER_SET_EMAIL_MP';
export const USER_SET_CREDIT_CARD_NUMBER = 'USER_SET_CREDIT_CARD_NUMBER';
export const USER_SET_CREDIT_CARD_OWNER = 'USER_SET_CREDIT_CARD_OWNER';
export const USER_SET_CREDIT_CARD_OWNER_ID = 'USER_SET_CREDIT_CARD_OWNER_ID';
export const USER_SET_COUPON_ID = 'USER_SET_COUPON_ID';
export const USER_SET_CREDIT_CARD_OWNER_TAX_ID = 'USER_SET_CREDIT_CARD_OWNER_TAX_ID';
export const USER_SET_CREDIT_CARD_DUE_DATE = 'USER_SET_CREDIT_CARD_DUE_DATE';
export const USER_SET_SELLER_ID = 'USER_SET_SELLER_ID';

//PET
export const PET_RESET = 'PET_RESET';
export const PET_SET_ID = 'PET_SET_ID';
export const PET_SET_NAME = 'PET_SET_NAME';
export const PET_SET_BIRTHDATE = 'PET_SET_BIRTHDATE';
export const PET_SET_GENDER_ID = 'PET_SET_GENDER_ID';
export const PET_SET_SPECIE_ID = 'PET_SET_SPECIE_ID';
export const PET_SET_BREED_ID = 'PET_SET_BREED_ID';
export const PET_SET_HEALTHPLAN_ID = 'PET_SET_HEALTHPLAN_ID';
export const PET_SET_SPECIES = 'PET_SET_SPECIES';
export const PET_SET_HEALTHPLANS = 'PET_SET_HEALTHPLANS';
export const PET_SET_BREEDS = 'PET_SET_BREEDS';
export const PET_SET_PETS = 'PET_SET_PETS';
export const PET_SET_ERROR = 'PET_SET_ERROR';
export const PET_ADD = 'PET_ADD';
export const PET_REMOVE = 'PET_REMOVE';
export const PET_SET_USER_ID = 'PET_SET_USER_ID';
export const PET_SET_TOTAL_PRICE = 'PET_SET_TOTAL_PRICE';
export const PET_SET_PHOTO = 'PET_SET_PHOTO';

//PETS
export const PETS_LIST_SET_HEIGHT = 'PETS_LIST_SET_HEIGHT';
export const PETS_LIST_SET_LIST = 'PETS_LIST_SET_LIST';
export const PETS_LIST_SET_SELECTED_PET = 'PETS_LIST_SET_SELECTED_PET';
export const PETS_LIST_UPDATE_FILTER = 'PETS_LIST_UPDATE_FILTER';

//TERMS
export const TERMS_SET_POST_VALIDATION = 'TERMS_SET_POST_VALIDATION';
export const TERMS_SET_PREEXISTANCES = 'TERMS_SET_PREEXISTANCES';
export const TERMS_SET_DEFICENCIES = 'TERMS_SET_DEFICENCIES';
export const TERMS_SET_ACCEPTANCE = 'TERMS_SET_ACCEPTANCE';
export const TERMS_SET_ERROR = 'TERMS_SET_ERROR';

//CREDENTIAL
export const CREDENTIAL_RESET = 'CREDENTIAL_RESET';
export const CREDENTIAL_SET = 'CREDENTIAL_SET';

//PETS EDITOR
export const PETS_EDITOR_UPDATE_FIELD = 'PETS_EDITOR_UPDATE_FIELD';
export const PETS_EDITOR_RESET = 'PETS_EDITOR_RESET';
export const PETS_EDITOR_SET_SPECIES = 'PETS_EDITOR_SET_SPECIES';
export const PETS_EDITOR_SET_BREEDS = 'PETS_EDITOR_SET_BREEDS';
export const PETS_EDITOR_SET_HEALTHPLANS = 'PETS_EDITOR_SET_HEALTHPLANS';
export const PETS_EDITOR_UPDATE_SPECIE = 'PETS_EDITOR_UPDATE_SPECIE';
export const PETS_EDITOR_UPDATE_BREED = 'PETS_EDITOR_UPDATE_BREED';
export const PETS_EDITOR_UPDATE_PHOTO = 'PETS_EDITOR_UPDATE_PHOTO';

//USERS EDITOR
export const USERS_EDITOR_UPDATE_FIELD = 'PETS_EDITOR_UPDATE_FIELD';
export const USERS_EDITOR_RESET = 'PETS_EDITOR_RESET';
export const USERS_EDITOR_SET_STATES = 'USERS_EDITOR_SET_STATES';
export const USERS_EDITOR_SET_CITIES = 'USERS_EDITOR_SET_CITIES';
export const USERS_EDITOR_UPDATE_STATE = 'USERS_EDITOR_UPDATE_STATE';
export const USERS_EDITOR_UPDATE_CITY = 'USERS_EDITOR_UPDATE_CITY';
export const USERS_EDITOR_CHANGE_PASSWORD_DIALOG = 'USERS_EDITOR_CHANGE_PASSWORD_DIALOG';
export const USERS_EDITOR_CLEAR_PASSWORD_DIALOG = 'USERS_EDITOR_CLEAR_PASSWORD_DIALOG';
export const USERS_EDITOR_SET_SELLERS = 'USERS_EDITOR_SET_SELLERS';
export const USERS_EDITOR_CHANGE_PAYMENT_DIALOG = 'USERS_EDITOR_CHANGE_PAYMENT_DIALOG';

//EXPORT
export const EXPORTCSV_SET_DATA = 'REPORT_SET_DATA';
export const EXPORTCSV_LIST_SET_HEIGHT = 'EXPORTCSV_LIST_SET_HEIGHT';

//PAYMENT
export const PAYMENT_SET_COUPON_TEXT = 'PAYMENT_SET_COUPON_TEXT';
export const PAYMENT_SET_COUPON = 'PAYMENT_SET_COUPON';
export const PAYMENT_RESET = 'PAYMENT_RESET';
export const PAYMENT_SET_TOTAL_VALUE = 'PAYMENT_SET_TOTAL_VALUE';
export const PAYMENT_SET_ORIGINAL_VALUE = 'PAYMENT_SET_ORIGINAL_VALUE';
