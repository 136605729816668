import * as types from '../constants/actions';

export function redirectTo(history, value) {
  return function (dispatch) {
    history.push(`/${value}`);
    dispatch({type: null});
  }
}

export function toggleDrawer() {
  return {
    type: types.CONTAINER_TOGGLE_DRAWER
  };
}

export function setSearchCriteria(value) {
  return {
    type: types.CONTAINER_SET_CRITERIA,
    value
  };
}

export function togglePaymentPendingDialog(value) {
  return {
    type: types.CONTAINER_TOGGLE_DIALOG,
    value
  }
}
