import * as types from '../constants/actions';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as PetEditorActions from '../actions/petsEditor';
import * as OverlayActions from '../actions/overlay';
import * as PaymentActions from '../actions/payment';
import fetch from 'isomorphic-fetch';
import async from 'async';

export function updateFilter(value) {
  return {type: types.PETS_LIST_UPDATE_FILTER, value};
}

export function updateHeight(value) {
  return {
    type: types.PETS_LIST_SET_HEIGHT,
    value
  };
}

export function setPetsList(values) {
  return {
    type: types.PETS_LIST_SET_LIST,
    value: values
  };
}

export function setSelectedPet(value) {
  return {
    type: types.PETS_LIST_SET_SELECTED_PET,
    value
  };
}

export function selectPet(value,history) {
  return function (dispatch, getState) {
    dispatch(setSelectedPet(value));
    history.push(`/admin-pets-edit`)
  }
}

export function createPet(history, userId) {
  return function (dispatch, getState) {
    dispatch(setSelectedPet(null));
    dispatch(PetEditorActions.updateField({ attr: 'UserId', value: userId }));
    history.push(`/admin-pets-edit`)
  }
}

export function getInitialData(UserId) {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getPets = function(data, cb) {
      fetch(`${_uri}${UserId === 0 ? '/api/pets' : `/api/users/${UserId}/pets`}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ` + getState().login.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          if (response.status === 200) {
            response.json()
            .then(data => {
              cb(null,data);
            })
          } else {
            cb(null,[]);
          }
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      getPets
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          if (getState().petsList.petsFilterValue !== '') {
            let _result = [];
            switch (getState().petsList.petsFilterType) {
              case 0:
                _result = result.filter((x) => (`${x.User.userName} ${x.User.userLastName}`).toUpperCase().includes(getState().petsList.petsFilterValue.toUpperCase()));
                break;
              case 1:
                _result = result.filter((x) => x.User.userIdNumber === getState().petsList.petsFilterValue);
                break;
              case 2:
                _result = result.filter((x) => x.petName.toUpperCase().includes(getState().petsList.petsFilterValue.toUpperCase()));
                break;
              default:
                _result = result;
            }
            dispatch(setPetsList(_result));
          } else {
            dispatch(setPetsList(result));
          }
        }
        dispatch(setSelectedPet(null));
        if (result && result.length > 0) dispatch(PaymentActions.checkPayment());
    });
  }
}
