import React from 'react';
import plans from '../resources/img/ospan-planes.png';
import { withStyles } from '@material-ui/core/styles';
import SignupContainer from './signupContainer';

const styles = theme => ({
  card:{
    height:'10rem',
  }
});

class Plans extends React.Component {
  render() {
    return (
      <SignupContainer activeStep={1} history={this.props.history} action={null}>
        <div className='plansContainer'>
          <img className='plans' alt='planes' src={plans} />
        </div>
      </SignupContainer>
    );
  }
}

export default (withStyles(styles)(Plans));
