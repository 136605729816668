import { PETS_EDITOR_UPDATE_FIELD, PETS_LIST_SET_SELECTED_PET, PETS_EDITOR_RESET, PETS_EDITOR_SET_SPECIES, PETS_EDITOR_SET_BREEDS, PETS_EDITOR_SET_HEALTHPLANS, PETS_EDITOR_UPDATE_SPECIE, PETS_EDITOR_UPDATE_BREED, PETS_EDITOR_UPDATE_PHOTO} from '../constants/actions';

const _newPet = { id: 0, petName: "", petStatus: "A", petPhoto: {}, Breed: { id: 0, breedName: 'Ninguna', Specie: { id: 0, specieName: 'Ninguna' }}, BreedId: 0, petComment: '', petBirthDate: Date.now(), petDeathDate: null, petSignUpDate: Date.now()};

const INITIAL_STATE = {
  pet: {..._newPet},
  Species: [],
  Breeds: [],
  HealthPlans: [],
  selectedSpecieId: 0,
  newPhoto: false
};

export default function petsEditor(state = INITIAL_STATE, action) {
  let _state = {...state};
  let _found = false;
  switch (action.type) {
    case PETS_EDITOR_UPDATE_FIELD:
      _state.pet[action.value.attr] = action.value.value;
      return _state;
    case PETS_EDITOR_UPDATE_SPECIE:
      _state.pet.Breed.id = 0;
      _state.pet.BreedId = 0;
      _state.pet.Breed.breedName = 'Ninguna';
      _state.pet.Breed.Specie.id = action.value;
      if (action.value !== 0) {
        for (let i = 0; i < _state.Species.length && !_found; i++) {
          if (_state.Species[i].id === action.value) {
            _found = true;
            _state.pet.Breed.Specie.specieName = _state.Species[i].specieName;
          }
        }
      } else {
        _state.pet.Breed.Specie.specieName = 'Ninguna';
      }
      return _state;
    case PETS_EDITOR_UPDATE_BREED:
      _state.pet.Breed.id = action.value;
      _state.pet.BreedId = action.value;
      if (action.value !== 0) {
        for (let i = 0; i < _state.Breeds.length && !_found; i++) {
          if (_state.Breeds[i].id === action.value) {
            _found = true;
            _state.pet.Breed.breedName = _state.Breeds[i].breedName;
          }
        }
      } else {
        _state.pet.Breed.breedName = 'Ninguna';
      }
      return _state;
    case PETS_EDITOR_RESET:
      return {...INITIAL_STATE};
    case PETS_LIST_SET_SELECTED_PET:
      if (action.value) {
        _state.pet = action.value;
      } else {
        _state.pet = JSON.parse(JSON.stringify(_newPet));
      }
      return _state;
    case PETS_EDITOR_SET_SPECIES:
      return {..._state, Species: [...action.values]};
    case PETS_EDITOR_SET_BREEDS:
      return {..._state, Breeds: [...action.values]};
    case PETS_EDITOR_SET_HEALTHPLANS:
      return {..._state, HealthPlans: [...action.values]};
    case PETS_EDITOR_UPDATE_PHOTO:
      return {..._state, newPhoto: action.value};
    default:
      return state;
  }
}
