import { BREEDS_EDITOR_UPDATE_FIELD, BREEDS_LIST_SET_SELECTED_BREED} from '../constants/actions';

const _initBreed={id: 0, breedName: "", breedStatus: "A"}

export default function breedsEditor(state = _initBreed, action) {
  let _state= {...state};
  switch (action.type) {
    case BREEDS_EDITOR_UPDATE_FIELD:
      _state[action.value.attr] = action.value.value;
      return _state;
    case BREEDS_LIST_SET_SELECTED_BREED:
      return action.value?action.value:_initBreed;
    default:
      return state;
  }
}
