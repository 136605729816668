import * as types from '../constants/actions';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';
import fetch from 'isomorphic-fetch';
import async from 'async';

export function updateFilter(value) {
  return {type: types.USERS_LIST_UPDATE_FILTER, value};
}

export function updateHeight(value) {
  return {
    type: types.USERS_LIST_SET_HEIGHT,
    value
  };
}

export function setUsersList(values) {
  return {
    type: types.USERS_LIST_SET_LIST,
    value: values
  };
}

export function setSelectedUser(values) {
  return {
    type: types.USERS_LIST_SET_SELECTED_USER,
    value: values
  };
}

export function selectUser(values,history) {
  return function (dispatch, getUser) {
    dispatch(setSelectedUser(values));
    history.push(`/admin-users-edit${ values ? ( values.id ? `/${values.id}` : '') : '' }`);
  }
}

export function updatePayment(userId) {
  return {
    type: types.USERS_LIST_UPDATE_USER_PAYMENT,
    value: userId
  }
}

export function setPayment(userId) {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const setPayment = function(data, cbPayment) {
      fetch(`${_uri}/api/users/${userId}/payment/0`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + getState().login.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cbPayment(new Error(data.message));
            })
          } else {
            cbPayment(new Error(response.statusText));
          }
        } else {
          if (response.status === 200) {
            response.json()
            .then(data => {
              cbPayment(null,data);
            })
          } else {
            cbPayment(null,[]);
          }
        }
      })
      .catch(err => {
        cbPayment(err);
      });
    };

    async.waterfall([
      start,
      setPayment
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(getInitialData());
          dispatch(MessageActions.show(result.message));
        }
        dispatch(setSelectedUser(null));
    });
  }
}

export function getInitialData() {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getUsers = function(data, cb) {
      fetch(_uri + '/api/users/pets',
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ` + getState().login.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          if (response.status === 200) {
            response.json()
            .then(data => {
              cb(null,data);
            })
          } else {
            cb(null,[]);
          }
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      getUsers
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          if (getState().usersList.usersFilterValue !== '') {
            let _result = [];
            switch (getState().usersList.usersFilterType) {
              case 0:
                _result = result.filter((x) => (`${x.userName} ${x.userLastName}`).toUpperCase().includes(getState().usersList.usersFilterValue.toUpperCase()))
                break;
              case 1:
                _result = result.filter((x) => x.userIdNumber === getState().usersList.usersFilterValue);
                break;
              default:
                _result = result;
            }
            dispatch(setUsersList(_result));
          } else {
            dispatch(setUsersList(result));
          }
        }
        dispatch(setSelectedUser(null));
    });
  }
}
