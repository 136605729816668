import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Container from './container';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import * as CouponsEditorActions from '../actions/couponsEditor';
import MODULES from '../constants/modules';

class CouponEditor extends Component {
  render() {
    const readOnly = (((this.props.access & MODULES.COUPONS.level) === 0 && this.props.roleName !== 'SYSADMIN') || this.props.roleName === 'USER');
    let _title = this.props.selectedCoupon ? `Edición de cupón ${this.props.selectedCoupon.couponName}` : ' Alta de cupón';
    return (
      <Container {...this.props}>
        <div className='adminTitle'>{_title}</div>
        <Grid container spacing={16} style={{paddingLeft: 16, paddingRight: 16}}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Nombre'
              fullWidth={true}
              autoFocus={true}
              value={this.props.newCoupon.couponName}
              onChange={(e) => this.props.actions.couponsEditor.updateField({attr:'couponName', value:e.target.value})}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
        <Grid container spacing={16} style={{paddingLeft: 16, paddingRight: 16}}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              type="number"
              InputLabelProps={{ shrink: true, }}
              InputProps={{ inputProps: { max: 100, min: 0 } }}
              label='Porcentaje de descuento'
              fullWidth={true}
              autoFocus={true}
              value={this.props.newCoupon.couponPercentageDiscount}
              onChange={(e) => this.props.actions.couponsEditor.updateField({attr:'couponPercentageDiscount', value:e.target.value})}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
        <Grid container spacing={16} style={{paddingLeft: 16, paddingRight: 16}}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              type="number"
              InputLabelProps={{ shrink: true, }}
              InputProps={{ inputProps: { min: 0 } }}
              label='Importe de descuento'
              fullWidth={true}
              autoFocus={true}
              value={this.props.newCoupon.couponMoneyDiscount}
              onChange={(e) => this.props.actions.couponsEditor.updateField({attr:'couponMoneyDiscount', value:e.target.value})}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
        <Fab key='couponClose' size="small" color="secondary" aria-label="Discard" style={{position: 'fixed', top: '80px', right: '20px'}} onClick={() => this.props.history.goBack()}>
          <CloseIcon />
        </Fab>
        {
          readOnly ?
            null
          :
            <Fab key='couponSave' size="small" color="secondary" aria-label="Save" style={{position: 'fixed', bottom: '110px', right: '20px'}} onClick={() => this.props.actions.couponsEditor.saveCoupon(this.props.history)}>
              <SaveIcon />
            </Fab>
        }
        {
          this.props.selectedCoupon && !readOnly ?
            <Fab key='couponDelete' size="small" color="secondary" aria-label="Delete" style={{position: 'fixed', bottom: '60px', right: '20px'}} onClick={() => this.props.actions.couponsEditor.deleteCoupon(this.props.history)}>
              <DeleteIcon />
            </Fab>
          :
            null
        }
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedCoupon: state.couponsList.selectedCoupon,
    newCoupon: state.couponsEditor,
    access: state.login.access
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      couponsEditor: bindActionCreators(CouponsEditorActions, dispatch),
    }
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(CouponEditor);
