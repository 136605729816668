module.exports = {
  version:'1.0.4',
  server: 'https://apiospan.farosdelsur.com',
  discounts: [
    {qty:1, value: 0},
    {qty:2, value: 5},
    {qty:3, value: 10},
    {qty:4, value: 15},
    {qty:5, value: 20},
    {qty:6, value: 25},
    {qty:7, value: 30},
    {qty:8, value: 31},
    {qty:9, value: 32},
    {qty:10, value: 33},
    {qty:11, value: 34},
    {qty:12, value: 35},
    {qty:13, value: 36},
    {qty:14, value: 37},
    {qty:15, value: 38},
  ]
};
