import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SignupContainer from './signupContainer';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import * as paymentActions from '../actions/payment';
import * as userActions from '../actions/user';

const styles = theme => ({
  card:{
    height:'10rem',
  }
});

class Payment extends React.Component {
  componentDidMount() {
    this.props.actions.payment.setOriginalValue(this.props.totalPrice);
  }

  render() {
    return (
      <SignupContainer activeStep={4} history={this.props.history}>
        <div className='widthContainer'>
          <Grid container className='innerWidthContainer'>
            {
              moment().format('D') >= 15 ?
                <Grid item xs={12}>
                  <div className='signupPaymentAlertTitle'>IMPORTANTE:</div>
                  <div className='signupPaymentAlertContent'>{`Usted está abonando la última quincena del mes ${moment().format('M')}.`}</div>
                  <div className='signupPaymentAlertContent'>{`Las carencias comienzan a correr desde el ${moment().format('15/MM/YYYY')}.`}</div>
                  <div className='signupPaymentAlertContent'>{`Usted puede utilizar su cobertura a partir del ${moment().format('DD/MM/YYYY')}.`}</div>
                </Grid>
              :
                <Grid item xs={12}>
                  <div className='signupPaymentAlertTitle'>IMPORTANTE:</div>
                  <div className='signupPaymentAlertContent'>{`Las carencias comienzan a correr desde el ${moment().format('DD/MM/YYYY')}.`}</div>
                  <div className='signupPaymentAlertContent'>{`Usted puede utilizar su cobertura a partir del ${moment().format('DD/MM/YYYY')}.`}</div>
                </Grid>
            }
            <Grid item xs={12}>
              <div className='signupPaymentPrice'>{`Total cuota a abonar: $${this.props.totalValue}`}</div>
            </Grid>
            {
              moment().format('D') >= 15 ?
              <Grid item xs={12}>
                <div className='signupPaymentDiscount'>Recuerde que en este acto sólo abona la última quincena de este mes, es decir, la mitad del período y del monto de la cuota mensual.<br/>Aclaración: como está pagando medio mes su carencia comienza a contar también desde la mitad del mes, es decir, a partir del 15.</div>
              </Grid>
              :null
            }
            <Grid item xs={12}>
              <TextField
                margin="normal"
                InputLabelProps={{ shrink: true, }}
                label='Cupón de descuento'
                fullWidth={true}
                value={this.props.couponText}
                onChange={(event) => this.props.actions.payment.setCouponText(event.target.value)}
                disabled={this.props.couponValidated}
              />
            </Grid>
            <Grid item xs={12}>
              {
                this.props.couponValidated ?
                  <div className='signupPaymentDisclosure'>
                    <br/>{`Usted ha registrado un cupón de descuento que lo hace acreedor a un beneficio de${this.props.coupon.couponPercentageDiscount > 0 ? `l ${this.props.coupon.couponPercentageDiscount}%` : ` $${this.props.coupon.couponMpneyDiscount}`} sobre el importe final a abonar. El mismo ya se encuenta aplicado sobre el valor final.`}<br/><br/>
                  </div>
                : (
                    this.props.couponText !== '' ?
                      <Button fullWidth variant="contained" color="primary" onClick={() => this.props.actions.payment.validateCoupon(this.props.couponText, this.props.user.id)} disabled={this.props.couponValidated}>{'Validar Cupón de descuento'}</Button>
                    : null
                  )
              }
            </Grid>
            <Grid item xs={12}>
              <div className='signupPaymentDisclosure'>
                <br/>Por favor, a continuación seleccione la modalidad y complete los datos asociados a la forma de pago de la cuota mensual que se utilizará a partir del segundo mes, ya que el primero se abona en este acto<br/><br/>
              </div>
            </Grid>
            <Grid container item xs={12}>
              <FormControl
                fullWidth
                error={this.props.error.errorField === 'userSubscriptionMethod'}
                required
                style={{paddingTop: 16}}
              >
                <InputLabel shrink>Forma de pago mensual elegida</InputLabel>
                <Select
                  value={this.props.user.userSubscriptionMethod}
                  onChange={(event) => this.props.actions.user.setField('userSubscriptionMethod', event.target.value)}
                >
                  <MenuItem value="N">Pago Directo (Rapipago, Pago Online, PMC)</MenuItem>
                  <MenuItem value="C">Débito Automático VISA crédito</MenuItem>
                  <MenuItem value="D">Débito Automático VISA débito</MenuItem>
                  <MenuItem value="S">Débito Automático MASTERCARD crédito</MenuItem>
                </Select>
                {
                  this.props.error.errorField === 'userSubscriptionMethod' ?
                    <FormHelperText>{this.props.error.errorMessage}</FormHelperText>
                  : null
                }
              </FormControl>
            </Grid>
            {
              this.props.user.userSubscriptionMethod === 'N' ? null : (
                this.props.user.userSubscriptionMethod === 'M' ?
                  <Grid item xs={12}>
                    <TextField
                      key="userEmailMP"
                      error={this.props.error.errorField === 'userEmailMP'}
                      helperText={this.props.error.errorField === 'userEmailMP' ? this.props.error.errorMessage : null}
                      margin="normal"
                      InputLabelProps={{ shrink: true, }}
                      label="Email asociado a la suscripción"
                      fullWidth={true}
                      required
                      value={this.props.user.userEmailMP}
                      onChange={(event) => this.props.actions.user.setField('userEmailMP', event.target.value)}
                    />
                  </Grid>
                :
                  [
                    <Grid item xs={12}>
                      <TextField
                        key="userCreditCardNumber"
                        error={this.props.error.errorField === 'userCreditCardNumber'}
                        helperText={this.props.error.errorField === 'userCreditCardNumber' ? this.props.error.errorMessage : null}
                        margin="normal"
                        InputLabelProps={{ shrink: true, }}
                        label="Número de tarjeta"
                        fullWidth={true}
                        required
                        value={this.props.user.userCreditCardNumber}
                        onChange={(event) => this.props.actions.user.setField('userCreditCardNumber', event.target.value)}
                      />
                    </Grid>,
                    <Grid item xs={12}>
                      <TextField
                        key="userCreditCardOwnerFullName"
                        error={this.props.error.errorField === 'userCreditCardOwnerFullName'}
                        helperText={this.props.error.errorField === 'userCreditCardOwnerFullName' ? this.props.error.errorMessage : null}
                        margin="normal"
                        InputLabelProps={{ shrink: true, }}
                        label="Nombre y apellido del titular"
                        fullWidth={true}
                        required
                        value={this.props.user.userCreditCardOwnerFullName}
                        onChange={(event) => this.props.actions.user.setField('userCreditCardOwnerFullName', event.target.value)}
                      />
                    </Grid>,
                    <Grid item xs={12}>
                      <TextField
                        key="userCreditCardDueDate"
                        error={this.props.error.errorField === 'userCreditCardDueDate'}
                        helperText={this.props.error.errorField === 'userCreditCardDueDate' ? this.props.error.errorMessage : null}
                        margin="normal"
                        InputLabelProps={{ shrink: true, }}
                        label="Vencimiento de la tarjeta (MM/AA)"
                        fullWidth={true}
                        required
                        value={this.props.user.userCreditCardDueDate}
                        onChange={(event) => this.props.actions.user.setField('userCreditCardDueDate', event.target.value)}
                      />
                    </Grid>,
                    <Grid item xs={12}>
                      <TextField
                        key="userCreditCardOwnerIdNumber"
                        error={this.props.error.errorField === 'userCreditCardOwnerIdNumber'}
                        helperText={this.props.error.errorField === 'userCreditCardOwnerIdNumber' ? this.props.error.errorMessage : null}
                        margin="normal"
                        InputLabelProps={{ shrink: true, }}
                        label="DNI del titular (sólo números sin punto)"
                        fullWidth={true}
                        required
                        value={this.props.user.userCreditCardOwnerIdNumber}
                        onChange={(event) => this.props.actions.user.setField('userCreditCardOwnerIdNumber', event.target.value)}
                      />
                    </Grid>,
                    <Grid item xs={12}>
                      <TextField
                        key="userCreditCardOwnerTaxIdNumber"
                        error={this.props.error.errorField === 'userCreditCardOwnerTaxIdNumber'}
                        helperText={this.props.error.errorField === 'userCreditCardOwnerTaxIdNumber' ? this.props.error.errorMessage : null}
                        margin="normal"
                        InputLabelProps={{ shrink: true, }}
                        label="CUIT del titular (sólo números sin guiones)"
                        fullWidth={true}
                        value={this.props.user.userCreditCardOwnerTaxIdNumber}
                        onChange={(event) => this.props.actions.user.setField('userCreditCardOwnerTaxIdNumber', event.target.value)}
                      />
                    </Grid>
                  ]
              )
            }
            <Grid item xs={12} className='centeredButtonContainer'>
              <Button className='homeButton' variant="contained" color="secondary" onClick={() => this.props.actions.payment.createPreference()}>Abonar</Button>
            </Grid>
            <Grid item xs={12}>
              <div className='signupPaymentNext'>
                Una vez finalizado el pago le llegará a su mail los documentos que debe completar para la afiliación. Chequear en correo no deseado, en caso de no haber llegado escribirnos a info@ospan.com.ar que se los reenviaremos.
              </div>
            </Grid>
          </Grid>
        </div>
      </SignupContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.user.error,
    user: state.user.user,
    totalPrice: state.pet.totalPrice,
    totalValue: state.payment.totalValue,
    couponText: state.payment.couponText,
    couponValidated: state.payment.couponValidated,
    coupon: state.payment.Coupon
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      payment: bindActionCreators(paymentActions, dispatch),
      user: bindActionCreators(userActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Payment));
