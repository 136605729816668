import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import VirtualizedTable from './virtualizedTable';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Container from './container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import * as PetsListActions from '../actions/petsList';
import _ from 'underscore';

class AdminPetsPage extends Component {

  updateDimensions() {
    var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
    this.props.actions.PetsList.updateHeight(height - 64);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.updateDimensions();
    let customerId = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
    this.props.actions.PetsList.getInitialData(customerId);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    let rows = this.props.pets.map((_pet) => {
      return ({
        petName: _pet.petName,
        petGender: _pet.petGender === 'M' ? 'Macho' : 'Hembra',
        specieName: _pet.Breed.Specie.specieName,
        breedName: _pet.Breed.breedName,
        healthPlanName: _pet.HealthPlan.healthPlanName,
        userFullName: `${_pet.User.userName} ${_pet.User.userLastName}`,
        data: _pet
      });
    });

    rows = _.sortBy(rows, x => x.petName);

    let customerId = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;

    return (
      <Container {...this.props}>
        <div style={{height: this.props.height}}>
          <div className='adminTitle'>{this.props.match.params && this.props.match.params.id ? 'Mascotas del usuario' : (this.props.roleName === 'USER' ? 'Tus mascotas' : 'Listado de mascotas')}</div>
          <Grid container style={{paddingLeft: 16, paddingRight: 16, backgroundColor: '#eee'}}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                autoFocus
                margin="normal"
                InputLabelProps={{ shrink: true, }}
                label='Buscar'
                value={this.props.petsFilterValue}
                onChange={(e) => this.props.actions.PetsList.updateFilter({attr:'petsFilterValue', value:e.target.value})}
                onKeyDown={(e) => e.keyCode === 13 ? this.props.actions.PetsList.getInitialData(customerId) : null}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl
                fullWidth
                autoFocus
                style={{marginTop: 16}}
              >
                <InputLabel shrink>Atributo</InputLabel>
                <Select
                  value={this.props.petsFilterType}
                  onChange={(event) => this.props.actions.PetsList.updateFilter({attr:'petsFilterType', value:event.target.value})}
                >
                  <MenuItem key={`petsFilterType_0`} value={0}>Propietario</MenuItem>
                  <MenuItem key={`petsFilterType_1`} value={1}>DNI Propietario</MenuItem>
                  <MenuItem key={`petsFilterType_2`} value={2}>Nombre</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <VirtualizedTable
            rowCount={rows.length}
            rowGetter={({ index }) => rows[index]}
            onRowClick={(event) => this.props.actions.PetsList.selectPet(event.rowData.data,this.props.history)}
            columns={[
              { width: 120, flexGrow: 1.0, label: 'Nombre', dataKey: 'petName' },
              { width: 120, flexGrow: 1.0, label: 'Propietario', dataKey: 'userFullName' },
              { width: 120, label: 'Plan', dataKey: 'healthPlanName' }
            ]}
          />
        </div>
        {
          this.props.match.params && this.props.match.params.id ?
            <Fab key='userClose' size="small" color="secondary" aria-label="Discard" style={{position: 'fixed', top: '80px', right: '20px'}} onClick={() => this.props.history.goBack()}>
              <CloseIcon />
            </Fab>
          : null
        }
        {
          this.props.roleName === 'USER' || (this.props.selectedUser && this.props.selectedUser.id > 0) ?
            <Fab color='secondary' size='medium' style={{position: 'fixed', bottom: 60, right: 20}} onClick={() => this.props.actions.PetsList.createPet(this.props.history, this.props.roleName === 'USER' ? this.props.loginUserId : this.props.selectedUser.id)}>
              <AddIcon />
            </Fab>
          : null
        }
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    height: state.petsList.height,
    pets: state.petsList.pets,
    selectedPet: state.petsList.selectedPet,
    selectedUser: state.usersList.selectedUser,
    loginUserId: state.login.userId,
    roleName: state.login.roleName,
    petsFilterType: state.petsList.petsFilterType,
    petsFilterValue: state.petsList.petsFilterValue,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      PetsList: bindActionCreators(PetsListActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPetsPage);
