import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import VirtualizedTable from './virtualizedTable';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Container from './container';
import * as HealthPlansListActions from '../actions/healthPlansList';
import * as ContainerActions from '../actions/container';

class AdminHealthPlansPage extends Component {

  updateDimensions() {
    var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
    this.props.actions.HealthPlansList.updateHeight(height - 64);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.updateDimensions();
    this.props.actions.HealthPlansList.getInitialData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    return (
      <Container {...this.props}>
        <div style={{height: this.props.height}}>
          <div className='adminTitle'>Listado de Planes</div>
          <VirtualizedTable
            rowCount={this.props.healthPlans.length}
            rowGetter={({ index }) => this.props.healthPlans[index]}
            onRowClick={(event) => this.props.actions.HealthPlansList.selectCoupon(event.rowData,this.props.history)}
            columns={[
              { width: 120, flexGrow: 1.0, label: 'Nombre', dataKey: 'healthPlanName' },
            ]}
          />
        </div>
        <Fab color='secondary' size='medium' style={{position: 'fixed', bottom: 60, right: 20}}>
          <AddIcon onClick={() => this.props.actions.HealthPlansList.selectCoupon(null,this.props.history)}/>
        </Fab>

      </Container>

    );
  }
}

function mapStateToProps(state) {
  return {
    height: state.healthPlansList.height,
    healthPlans: state.healthPlansList.healthPlans,
    selectedCoupon: state.healthPlansList.selectedCoupon
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      HealthPlansList: bindActionCreators(HealthPlansListActions, dispatch),
      Container: bindActionCreators(ContainerActions, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminHealthPlansPage);
