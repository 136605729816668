import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  card:{
    height:'10rem',
  }
});

class Home extends React.Component {
  render() {
    return (
      <div className='home'>
        <div className='card'>
          <div className='homeTitle'>¡Bienvenido a OSPAN!</div>
          <div className='homeSubtitle'>Obra social para mascotas</div>
          <Grid container style={{marginTop: 24}}>
            <Grid item xs={12} sm={6} className='leftButton' >
              <Button className='homeButton' variant="contained" color='secondary' onClick={() => this.props.history.push('login')}>Iniciar sesión</Button>
            </Grid>
            <Grid item xs={12} sm={6} className='rightButton' >
              <Button className='homeButton' variant="contained" color='primary' onClick={() => this.props.history.push('signup')}>Asociarme</Button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default (withStyles(styles)(Home));
