import { CITIES_LIST_SET_HEIGHT, CITIES_LIST_SET_LIST, CITIES_LIST_SET_SELECTED_CITY,CITIES_LIST_SET_STATES,CITIES_LIST_SET_SELECTED_STATE } from '../constants/actions';

export default function citiesList(state = {height:0,cities:[],states:[],selectedCity:null,selectedState:0}, action) {
  switch (action.type) {
    case CITIES_LIST_SET_HEIGHT:
      return {...state, height: action.value};
    case CITIES_LIST_SET_LIST:
      return {...state,cities: action.value};
    case CITIES_LIST_SET_STATES:
      return {...state,states: action.value};
    case CITIES_LIST_SET_SELECTED_CITY:
      return {...state, selectedCity: action.value};
    case CITIES_LIST_SET_SELECTED_STATE:
      return {...state,selectedState:action.value,};
    default:
      return state;
  }
}
