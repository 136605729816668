import { CONTAINER_TOGGLE_DRAWER, CONTAINER_SET_CRITERIA, CONTAINER_TOGGLE_DIALOG, CONTAINER_PAYMENT_PENDING } from '../constants/actions';

const _state={
  drawer: false,
  criteria: '',
  paymentPendingDialog: null,
  paymentPending: null
}

export default function login(state = _state, action) {
  switch (action.type) {
  case CONTAINER_TOGGLE_DRAWER:
    return {...state, drawer: !state.drawer};
  case CONTAINER_TOGGLE_DIALOG:
    return {...state, paymentPendingDialog: action.value};
  case CONTAINER_SET_CRITERIA:
    return {...state, criteria: action.value};
  case CONTAINER_PAYMENT_PENDING:
  return {...state, paymentPending: action.value};
  default:
    return state;
  }
}
