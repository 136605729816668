import { OVERLAY_SHOW, OVERLAY_HIDE, OVERLAY_SET } from '../constants/actions';

export default function overlay(state = {open:false, message:''}, action) {
  switch (action.type) {
  case OVERLAY_SHOW:
    return {...state, open: true, message: action.value};
  case OVERLAY_HIDE:
    return {...state, open: false};
  case OVERLAY_SET:
    return {...state, message: action.value};
  default:
    return state;
  }
}
