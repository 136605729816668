import { SPECIES_EDITOR_UPDATE_FIELD, SPECIES_LIST_SET_SELECTED_SPECIE} from '../constants/actions';

const _initSpecie={id: 0, specieName: "", specieStatus: "A"}

export default function speciesEditor(state = _initSpecie, action) {
  let _state= {...state};
  switch (action.type) {
    case SPECIES_EDITOR_UPDATE_FIELD:
      _state[action.value.attr] = action.value.value;
      return _state;
    case SPECIES_LIST_SET_SELECTED_SPECIE:
      return action.value?action.value:_initSpecie;
    default:
      return state;
  }
}
