import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import SignupContainer from './signupContainer';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as termsActions from '../actions/terms';

const styles = theme => ({
  card:{
    height:'10rem',
  }
});

class Terms extends React.Component {
  render() {
    return (
      <SignupContainer activeStep={3} history={this.props.history} action={this.props.actions.terms.saveTerms}>
        <div className='widthContainer'>
          <Grid container className='innerWidthContainer' spacing={16}>
            <Grid item>
              <FormControl required error>
                <FormControlLabel
                  control={<Checkbox checked={this.props.termPostValidation} name="termPostValidation" onChange={(event) => this.props.actions.terms.setTermPostValidation(event.target.checked)} />}
                  label="Dentro de los próximos 30 días deberá concurrir a la sede veterinaria que le quede más cómodo de nuestra cartilla para concluir la afiliación en forma definitiva. Se hace el chequeo clínico de su mascota y el veterinario verifica la declaración jurada. De esta manera el proceso de incorporación de su mascota a OSPAN quedará completa y aceptada"
                />
                {
                  this.props.error.errorField === 'termPostValidation' ?
                    <FormHelperText>{this.props.error.errorMessage}</FormHelperText>
                  : null
                }
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl required error>
                <FormControlLabel
                  control={<Checkbox checked={this.props.termsPreExistances} name="termsPreExistances" onChange={(event) => this.props.actions.terms.setTermsPreExistances(event.target.checked)} />}
                  label="Si la mascota tiene preexistencias el plan que cubre las mismas es el plan 410. La cobertura es la misma que el plan 400, excepto en laboratorio, imágenes, cirugía e internación que estará cubierto al 50% sobre la preexistencia declarada."
                />
                {
                  this.props.error.errorField === 'termsPreExistances' ?
                    <FormHelperText>{this.props.error.errorMessage}</FormHelperText>
                  : null
                }
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl required error>
                <FormControlLabel
                  control={<Checkbox checked={this.props.termsDeficencies} name="termsPreExistances" onChange={(event) => this.props.actions.terms.setTermsDeficencies(event.target.checked)} />}
                  label="Existe un período de carencia para algunos servicios a partir del día en que realiza el pago online: Laboratorio, imágenes y vacunación: 30 días; Internación: 60 días; Cirugías: 150 días."
                />
                {
                  this.props.error.errorField === 'termsDeficencies' ?
                    <FormHelperText>{this.props.error.errorMessage}</FormHelperText>
                  : null
                }
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl required error>
                <FormControlLabel
                  control={<Checkbox checked={this.props.termsAcceptance} name="termsPreExistances" onChange={(event) => this.props.actions.terms.setTermsAcceptance(event.target.checked)} />}
                  label="Acepto los términos y condiciones"
                />
                {
                  this.props.error.errorField === 'termsAcceptance' ?
                    <FormHelperText>{this.props.error.errorMessage}</FormHelperText>
                  : null
                }
              </FormControl>
            </Grid>
            <p>Por cualquier consulta vía mail a <a href="mailto:info@ospan.com.ar" className="termsEmail">info@ospan.com.ar</a> o Whatsapp al <a href="https://wa.link/cmikba" className="termsWhatsapp">+54 911 2182-1583</a></p>
          </Grid>
        </div>
      </SignupContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.terms.error,
    termPostValidation: state.terms.termPostValidation,
    termsPreExistances: state.terms.termsPreExistances,
    termsDeficencies: state.terms.termsDeficencies,
    termsAcceptance: state.terms.termsAcceptance
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      terms: bindActionCreators(termsActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Terms));
