import {
  PAYMENT_SET_COUPON,
  PAYMENT_SET_COUPON_TEXT,
  PAYMENT_RESET,
  PAYMENT_SET_TOTAL_VALUE,
  PAYMENT_SET_ORIGINAL_VALUE,
} from '../constants/actions';

const INITAL_STATE = {
  Coupon: null,
  couponText: '',
  originalValue: 0,
  totalValue: 0,
  couponValidated: false,
};

export default function overlay(state = {...INITAL_STATE}, action) {
  switch (action.type) {
  case PAYMENT_RESET:
    return {...INITAL_STATE};
  case PAYMENT_SET_COUPON:
    return {...state, Coupon: action.value, couponValidated: (action.value ? true : false)};
  case PAYMENT_SET_ORIGINAL_VALUE:
    return {...state, originalValue: action.value, totalValue: action.value};
  case PAYMENT_SET_TOTAL_VALUE:
    return {...state, totalValue: action.value};
  case PAYMENT_SET_COUPON_TEXT:
    return {...state, couponText: action.value, totalValue: state.originalValue, couponValidated: false, Coupon: null};
  default:
    return state;
  }
}
