import { MESSAGE_SHOW, MESSAGE_HIDE } from '../constants/actions';

export default function message(state = {open: false, message:''}, action) {
  switch (action.type) {
  case MESSAGE_SHOW:
    return {open: true, value:action.value};
  case MESSAGE_HIDE:
    return {open: false, value:''};
  default:
    return state;
  }
}
