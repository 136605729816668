import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../actions/user';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import SignupContainer from './signupContainer';
import _ from "underscore";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  card:{
    height:'10rem',
  }
});

class SignUp extends React.Component {
  componentDidMount() {
    this.props.actions.user.getInitialData();
  }

  render() {
    const _sortedStates = _.sortBy(this.props.states, x => x.stateName);
    const _filteredCities = _.sortBy(this.props.cities.filter(x => x.StateId === this.props.user.StateId), x => x.cityName);
    const _sortedSellers = _.sortBy(this.props.sellers, x => x.sellerName);

    return (
      <SignupContainer activeStep={0} history={this.props.history} action={this.props.actions.user.saveUser}>
        <Grid container spacing={2} style={{paddingLeft: 24, paddingRight: 24}}>
          <Grid item xs={12} sm={6}>
            <TextField
              error={this.props.error.errorField === 'userName'}
              helperText={this.props.error.errorField === 'userName' ? this.props.error.errorMessage : null}
              autoComplete='given-name'
              margin='normal'
              InputLabelProps={{ shrink: true }}
              label='Nombre'
              fullWidth={true}
              required
              value={this.props.user.userName}
              onChange={(event) => this.props.actions.user.setField('userName', event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={this.props.error.errorField === 'userLastName'}
              helperText={this.props.error.errorField === 'userLastName' ? this.props.error.errorMessage : null}
              autoComplete='family-name'
              margin='normal'
              InputLabelProps={{ shrink: true, }}
              label='Apellido'
              fullWidth={true}
              required
              value={this.props.user.userLastName}
              onChange={(event) => this.props.actions.user.setField('userLastName', event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={this.props.error.errorField === 'userEmail'}
              helperText={this.props.error.errorField === 'userEmail' ? this.props.error.errorMessage : null}
              autoComplete='email'
              margin='normal'
              InputLabelProps={{ shrink: true, }}
              label='Email'
              fullWidth={true}
              required
              value={this.props.user.userEmail}
              onChange={(event) => this.props.actions.user.setField('userEmail', event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={this.props.error.errorField === 'userPhone'}
              helperText={this.props.error.errorField === 'userPhone' ? this.props.error.errorMessage : null}
              autoComplete='tel'
              margin='normal'
              InputLabelProps={{ shrink: true, }}
              label='Celular'
              fullWidth={true}
              required
              value={this.props.user.userPhone}
              onChange={(event) => this.props.actions.user.setField('userPhone', event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={this.props.error.errorField === 'userPassword'}
              helperText={this.props.error.errorField === 'userPassword' ? this.props.error.errorMessage : null}
              autoComplete='current-password'
              type='password'
              margin='normal'
              InputLabelProps={{ shrink: true, }}
              label='Contraseña'
              fullWidth={true}
              required
              value={this.props.user.userPassword}
              onChange={(event) => this.props.actions.user.setField('userPassword', event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={this.props.error.errorField === 'userRepeatPassword'}
              helperText={this.props.error.errorField === 'userRepeatPassword' ? this.props.error.errorMessage : null}
              autoComplete='current-password'
              type='password'
              margin='normal'
              InputLabelProps={{ shrink: true, }}
              label='Repetir contraseña'
              fullWidth={true}
              required
              value={this.props.user.userRepeatPassword}
              onChange={(event) => this.props.actions.user.setField('userRepeatPassword', event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={this.props.error.errorField === 'userIdNumber'}
              helperText={this.props.error.errorField === 'userIdNumber' ? this.props.error.errorMessage : null}
              autoComplete='id-number'
              margin='normal'
              InputLabelProps={{ shrink: true }}
              label='DNI'
              fullWidth={true}
              required
              value={this.props.user.userIdNumber}
              onChange={(event) => this.props.actions.user.setField('userIdNumber', event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={this.props.error.errorField === 'SellerId'}
              style={{marginTop: 16}}
            >
              <InputLabel shrink>Asesor</InputLabel>
              <Select
                value={this.props.user.SellerId}
                onChange={(event) => this.props.actions.user.setField('SellerId', event.target.value)}
              >
                {
                  this.props.sellers && _sortedSellers.map((_seller) => {
                    return (<MenuItem key={`signUpSeller_${_seller.id}`} value={_seller.id}>{_seller.sellerName}</MenuItem>)
                  })
                }
              </Select>
              {
                this.props.error.errorField === 'SellerId' ?
                  <FormHelperText>{this.props.error.errorMessage}</FormHelperText>
                : null
              }
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={this.props.error.errorField === 'StateId'}
              required
              style={{marginTop: 16}}
            >
              <InputLabel shrink>Provincia</InputLabel>
              <Select
                value={this.props.user.StateId}
                onChange={(event) => this.props.actions.user.setStateAndGetCities(event.target.value)}
              >
                {
                  this.props.states && _sortedStates.map((_state) => {
                    return (<MenuItem key={`signUpState_${_state.id}`} value={_state.id}>{_state.stateName}</MenuItem>)
                  })
                }
              </Select>
              {
                this.props.error.errorField === 'StateId' ?
                  <FormHelperText>{this.props.error.errorMessage}</FormHelperText>
                : null
              }
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={this.props.error.errorField === 'CityId'}
              required
              style={{marginTop: 16}}
            >
              <InputLabel shrink>Ciudad</InputLabel>
              <Select
                value={this.props.user.CityId}
                onChange={(event) => this.props.actions.user.setField('CityId', event.target.value)}
              >
                {
                  this.props.cities && _filteredCities.map((_city) => {
                    return (<MenuItem key={`signUpCity_${_city.id}`} value={_city.id}>{_city.cityName}</MenuItem>)
                  })
                }
              </Select>
              {
                this.props.error.errorField === 'CityId' ?
                  <FormHelperText>{this.props.error.errorMessage}</FormHelperText>
                : null
              }
            </FormControl>
          </Grid>
        </Grid>
      </SignupContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.user.error,
    user: state.user.user,
    states: state.user.states,
    cities: state.user.cities,
    sellers: state.user.sellers
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      user: bindActionCreators(userActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignUp));
