import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import VirtualizedTable from './virtualizedTable';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Container from './container';
import * as CitiesListActions from '../actions/citiesList';
import * as ContainerActions from '../actions/container';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'underscore';

class AdminCitiesPage extends Component {

  updateDimensions() {
    var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
    this.props.actions.CitiesList.updateHeight(height - 220);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.updateDimensions();
    this.props.actions.CitiesList.getInitialData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {

    let _this=this;
    let _filteredCities=_.sortBy(this.props.cities.filter((item)=>{return item.StateId===_this.props.selectedState}),x =>x.cityName);
    return (
      <Container {...this.props}>
        <div style={{height: this.props.height}}>
          <div className='adminTitle'>Listado de Localidades</div>
          <Grid container spacing={16} style={{paddingLeft: 16, paddingRight: 16}}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                required
                style={{marginTop: 16}}
              >
                <InputLabel shrink>Provincia</InputLabel>
                <Select
                  value={this.props.selectedState}
                  onChange={(event) => this.props.actions.CitiesList.setSelectedState(event.target.value)}
                >
                  <MenuItem key={`state_0`} value={0}>{'Ninguna'}</MenuItem>
                  {
                    this.props.states && this.props.states.map((_state) => {
                      return (<MenuItem key={`signUpCity_${_state.id}`} value={_state.id}>{_state.stateName}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <VirtualizedTable
            rowCount={_filteredCities.length}
            rowGetter={({ index }) => _filteredCities[index]}
            onRowClick={(event) => this.props.actions.CitiesList.selectCity(event.rowData,this.props.history)}
            columns={[
              { width: 120, flexGrow: 1.0, label: 'Nombre', dataKey: 'cityName' },
            ]}
          />
        </div>
        {this.props.selectedState?
          <Fab color='secondary' size='medium' style={{position: 'fixed', bottom: 60, right: 20}}>
            <AddIcon onClick={() => this.props.actions.CitiesList.selectCity(null,this.props.history)}/>
          </Fab>
        :
          null
        }

      </Container>

    );
  }
}

function mapStateToProps(state) {
  return {
    height: state.citiesList.height,
    cities: state.citiesList.cities,
    states: state.citiesList.states,
    selectedCity: state.citiesList.selectedCity,
    selectedState: state.citiesList.selectedState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      CitiesList: bindActionCreators(CitiesListActions, dispatch),
      Container: bindActionCreators(ContainerActions, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminCitiesPage);
