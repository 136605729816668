import React, { Component } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import PetsIcon from '@material-ui/icons/Pets';
import ExploreIcon from '@material-ui/icons/Explore';
import RoomIcon from '@material-ui/icons/Room';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import FaceIcon from '@material-ui/icons/Face';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DescriptionIcon from '@material-ui/icons/Description';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber'
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import logo from '../resources/img/logo-nb-w.png';
import * as LoginActions from '../actions/login';
import * as ContainerActions from '../actions/container';
import * as PaymentActions from '../actions/payment';
import MODULES from '../constants/modules';

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    color:'#d7a27b',
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
      width: 'auto',
    },
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  logoutIcon:{
    color:'#d7a27b',
  },
  menuItem:{
    color:'#d7a27b',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '40px',
    height: 'auto',
    width: 'auto',
  },
});

class Container extends Component {
  redirectTo(value) {
    this.props.actions.container.redirectTo(this.props.history, value);
  }

  logout() {
    this.props.actions.login.logout(this.props.history);
  }

  toggleDrawer() {
    this.props.actions.container.toggleDrawer();
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <AppBar color='default' position="static" style={{width: '100%', padding: 0, margin: 0, position: 'fixed', left: 0, top: 0,background:'#272c45'}}>
          <Toolbar style={{width: '100%', padding: 0, paddingLeft: 12}}>
            <IconButton key='toolbarMenuButton' className={classes.menuButton} color="inherit" aria-label="Open drawer" onClick={() => this.toggleDrawer()}>
              <MenuIcon key='toolbarMenuButtonIcon' />
            </IconButton>
            <div style={{flexGrow: 1, padding: 0,cursor:'pointer'}}>
              <img alt='logo' className={classes.logo} src={logo} onClick={() => this.redirectTo(this.props.roleName === 'USER' ? 'admin-pets' : 'admin-users')} />
            </div>
            <p style = {{color:'#d7a27b'}}>{'Bienvenido ' +  this.props.userName}</p>
            {
              this.props.roleName === 'USER' && this.props.paymentPending ?
                <div className="tooltip blink_me" style={{backgroundColor: 'firebrick', color:'#fff', borderRadius: 16, cursor: 'pointer', fontSize: 14, paddingLeft: 8, paddingRight: 8, marginLeft: 8, textAlign: 'center'}} onClick={()=>this.props.actions.Payment.createPreferenceFromScratch(this.props.userId)}>
                  PAGO PENDIENTE
                  <span class="tooltiptext">Click para abonar</span>
                </div>
              : null
            }
            <div>
              <IconButton aria-haspopup="true" onClick={() => this.logout()} className={classes.logoutIcon}>
                <LogoutIcon />
              </IconButton>
            </div>
            <div style={{width: '16px'}}>
            </div>
          </Toolbar>
        </AppBar>
          <Drawer open={this.props.drawer} onClose={() => this.toggleDrawer()}>
            <div
              tabIndex={0}
              role="button"
              onClick={() => this.toggleDrawer()}
              onKeyDown={() => this.toggleDrawer()}
            >
              {
                this.props.roleName !== 'SYSADMIN' ?
                <ListItem button key='Perfil' onClick={() => this.redirectTo(`admin-users-edit/${this.props.userId}`)}>
                  <ListItemIcon className={classes.menuItem}><PersonIcon /></ListItemIcon>
                  <ListItemText classes={{primary:classes.menuItem}} primary='Perfil' />
                </ListItem>
                : null
              }
              {
                this.props.modules & MODULES.USERS.level ?
                <ListItem button key='Usuarios' onClick={() => this.redirectTo('admin-users')}>
                  <ListItemIcon className={classes.menuItem}><PersonIcon /></ListItemIcon>
                  <ListItemText classes={{primary:classes.menuItem}} primary='Usuarios' />
                </ListItem>
                : null
              }
              {
                this.props.modules & MODULES.PETS.level ?
                <ListItem button key='Mascotas' onClick={() => this.redirectTo('admin-pets')}>
                  <ListItemIcon className={classes.menuItem}><PetsIcon /></ListItemIcon>
                  <ListItemText classes={{primary:classes.menuItem}} primary='Mascotas' />
                </ListItem>
                : null
              }
              {
                this.props.modules & MODULES.STATES.level ?
                <ListItem button key='admin-states' onClick={() => this.redirectTo('admin-states')}>
                  <ListItemIcon className={classes.menuItem}><ExploreIcon /></ListItemIcon>
                  <ListItemText classes={{primary:classes.menuItem}} primary='Provincias' />
                </ListItem>
                : null
              }
              {
                this.props.modules & MODULES.CITIES.level ?
                <ListItem button key='Localidades' onClick={() => this.redirectTo('admin-cities')}>
                  <ListItemIcon className={classes.menuItem}><RoomIcon /></ListItemIcon>
                  <ListItemText classes={{primary:classes.menuItem}} primary='Localidades' />
                </ListItem>
                : null
              }
              {
                this.props.modules & MODULES.HEALTHPLANS.level ?
                <ListItem button key='Planes' onClick={() => this.redirectTo('admin-healthPlans')}>
                  <ListItemIcon className={classes.menuItem}><LoyaltyIcon /></ListItemIcon>
                  <ListItemText classes={{primary:classes.menuItem}} primary='Planes' />
                </ListItem>
                : null
              }
              {
                this.props.modules & MODULES.SPECIES.level ?
                <ListItem button key='Especies' onClick={() => this.redirectTo('admin-species')}>
                  <ListItemIcon className={classes.menuItem}><FaceIcon /></ListItemIcon>
                  <ListItemText classes={{primary:classes.menuItem}} primary='Especies' />
                </ListItem>
                : null
              }
              {
                this.props.modules & MODULES.BREEDS.level ?
                <ListItem button key='Razas' onClick={() => this.redirectTo('admin-breeds')}>
                  <ListItemIcon className={classes.menuItem}><FingerprintIcon /></ListItemIcon>
                  <ListItemText classes={{primary:classes.menuItem}} primary='Razas' />
                </ListItem>
                : null
              }
              {
                this.props.modules & MODULES.SELLERS.level ?
                <ListItem button key='Asesores' onClick={() => this.redirectTo('admin-sellers')}>
                  <ListItemIcon className={classes.menuItem}><BusinessCenterIcon /></ListItemIcon>
                  <ListItemText classes={{primary:classes.menuItem}} primary='Asesores' />
                </ListItem>
                : null
              }
              {
                this.props.modules & MODULES.COUPONS.level ?
                <ListItem button key='Cupones' onClick={() => this.redirectTo('admin-coupons')}>
                  <ListItemIcon className={classes.menuItem}><ConfirmationNumberIcon /></ListItemIcon>
                  <ListItemText classes={{primary:classes.menuItem}} primary='Cupones' />
                </ListItem>
                : null
              }
              {
                this.props.modules & MODULES.EXPORT.level ?
                <ListItem button key='Exportacion' onClick={() => this.redirectTo('admin-exportcsv')}>
                  <ListItemIcon className={classes.menuItem}><DescriptionIcon /></ListItemIcon>
                  <ListItemText classes={{primary:classes.menuItem}} primary='Exportación' />
                </ListItem>
                : null
              }
            </div>
          </Drawer>
        <div style={{marginTop: '56px'}}>
          {this.props.children}
        </div>
        {
          this.props.paymentPendingDialog !== null ?
            <Dialog open={this.props.paymentPendingDialog} onClose={()=>this.props.actions.container.togglePaymentPendingDialog(false)} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Pendiente de pago</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Según nuestros registros, su cuenta registra un saldo pendiente de pago, por favor regularice su situación.
                </DialogContentText>
                <DialogContentText>
                  En caso de haber efectuado el pago y que el mismo no se haya reflejado en su cuenta, por favor comuníquese con nosotros.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>this.props.actions.container.togglePaymentPendingDialog(false)} color="primary">
                  Cancelar
                </Button>
                <Button onClick={()=>this.props.actions.Payment.createPreferenceFromScratch(this.props.userId)} color="primary">
                  Pagar
                </Button>
              </DialogActions>
            </Dialog>
          : null
        }
        <div style={{textAlign:'center',height: '30px',width:'100%',position:'fixed',bottom:'0px',background:'#272c45',color:'#d7a27b',borderTop:'4px solid #dddddd', paddingTop: 8}}>
          {`Version 1.0.4`}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.container.selectedTab,
    criteria: state.container.criteria,
    drawer: state.container.drawer,
    roleName: state.login.roleName,
    userName: state.login.userName,
    userId: state.login.userId,
    modules: state.login.modules,
    paymentPendingDialog: state.container.paymentPendingDialog,
    paymentPending: state.container.paymentPending
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login: bindActionCreators(LoginActions, dispatch),
      container: bindActionCreators(ContainerActions, dispatch),
      Payment: bindActionCreators(PaymentActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Container));
