import * as types from '../constants/actions';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';
import fetch from 'isomorphic-fetch';
import async from 'async';

export function updateField(value) {return {type: types.SELLERS_EDITOR_UPDATE_FIELD, value};}

export function saveSeller(history) {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const postSeller = function(data,cb) {
      let _data = getState().sellersEditor;
      fetch(_uri + `/api/sellers${_data.id===0? '' : `/${_data.id}`}`,
        {
          method: _data.id === 0 ? 'POST' : 'PUT',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(_data)
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(_seller => {
            cb(null,null);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      postSeller
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(MessageActions.show(getState().sellersEditor.id===0?'El asesor ha sido dado de alta.':'El asesor ha sido modificado.'));
          history.goBack();
        }
    });
  }
}

export function deleteSeller(history) {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const deleteSeller = function(data,cb) {
      let _data = getState().sellersEditor;
      fetch(_uri + `/api/sellers/${_data.id}`,
        {
          method:'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(_seller => {
            cb(null,null);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      deleteSeller
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(MessageActions.show('El asesor ha sido eliminado.'));
          history.goBack();
        }
    });
  }
}
