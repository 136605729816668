import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as petActions from '../actions/pet';
import * as messageActions from '../actions/message';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import SignupContainer from './signupContainer';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dropzone from 'react-dropzone';
import FormHelperText from '@material-ui/core/FormHelperText';
import PetsIcon from '@material-ui/icons/Pets';
import Chip from '@material-ui/core/Chip';
import { KeyboardDatePicker } from "@material-ui/pickers";
import _ from 'underscore';

const styles = theme => ({
  card:{
    height:'10rem',
  }
});

class Pets extends React.Component {

  onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length > 0) {
      this.props.actions.pet.setField('petPhoto', acceptedFiles[0]);
    } else {
      this.props.actions.message.show('Formato de imagen no reconocido');
    }
  }

  componentDidMount() {
    this.props.actions.pet.getInitialData();
  }

  render() {
    const _species = _.sortBy(this.props.species, x => x.specieName);
    const _breeds = _.sortBy(this.props.breeds, x => x.breedName);
    return (
      <SignupContainer activeStep={2} history={this.props.history} action={this.props.actions.pet.savePets}>
        <Grid container spacing={2} style={{paddingLeft: 24, paddingRight: 24}}>
          <Grid item xs={12}>
            <TextField
              error={this.props.error.errorField === 'petName'}
              helperText={this.props.error.errorField === 'petName' ? this.props.error.errorMessage : null}
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Nombre'
              fullWidth={true}
              required
              value={this.props.pet.petName}
              onChange={(event) => this.props.actions.pet.setField('petName', event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <KeyboardDatePicker
              autoOk
              required
              style={{width:'100%', marginTop: 0, marginBottom: 8}}
              label="Fecha de nacimiento estimada"
              format="DD/MM/YYYY"
              maxDate={Date.now()}
              cancelLabel="CANCELAR"
              okLabel="ACEPTAR"
              value={this.props.pet.petBirthDate}
              InputAdornmentProps={{ position: "start" }}
              onChange={date => this.props.actions.pet.setField('petBirthDate', date)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              style={{paddingTop: 16}}
              error={this.props.error.errorField === 'petPhoto'}
            >
              <InputLabel shrink id="label">Foto</InputLabel>
              <Dropzone onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles)} multiple={false} accept={[".jpg",".jpeg",".png"]}>
                {({getRootProps, getInputProps, isDragActive}) => {
                  return (
                    <div
                      {...getRootProps()}
                      style={{cursor:'pointer', borderBottomWidth: 1, borderBottomColor: 'black', color: '#00000066'}}
                    >
                      <input {...getInputProps()} />
                      {
                        isDragActive ?
                          <p className='dropzonePlaceholder'>Arrastra aquí la foto</p>
                        : (this.props.pet.petPhoto && this.props.pet.petPhoto.name ?
                          <p className='dropzonePlaceholder'>{this.props.pet.petPhoto.name}</p>
                        : <p className='dropzonePlaceholder'>Click aquí para agregar la foto</p>)
                      }
                    </div>
                  )
                }}
              </Dropzone>
              {
                this.props.error.errorField === 'petPhoto' ?
                  <FormHelperText>{this.props.error.errorMessage}</FormHelperText>
                : null
              }
            </FormControl>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={this.props.error.errorField === 'SpecieId'}
              required
              style={{paddingTop: 16}}
            >
              <InputLabel shrink>Especie</InputLabel>
              <Select
                value={this.props.pet.SpecieId}
                onChange={(event) => this.props.actions.pet.setSpecieAndGetBreeds(event.target.value)}
              >
                {
                  _species && _species.map((_specie) => {
                    return (<MenuItem key={`petSpecie_${_specie.id}`} value={_specie.id}>{_specie.specieName}</MenuItem>)
                  })
                }
              </Select>
              {
                this.props.error.errorField === 'SpecieId' ?
                  <FormHelperText>{this.props.error.errorMessage}</FormHelperText>
                : null
              }
            </FormControl>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={this.props.error.errorField === 'BreedId'}
              required
              style={{paddingTop: 16}}
            >
              <InputLabel shrink>Raza</InputLabel>
              <Select
                value={this.props.pet.BreedId}
                onChange={(event) => this.props.actions.pet.setField('BreedId', event.target.value)}
              >
                {
                  _breeds && _breeds.map((_breed) => {
                    return (<MenuItem key={`petBreed_${_breed.id}`} value={_breed.id}>{_breed.breedName}</MenuItem>)
                  })
                }
              </Select>
              {
                this.props.error.errorField === 'BreedId' ?
                  <FormHelperText>{this.props.error.errorMessage}</FormHelperText>
                : null
              }
            </FormControl>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={this.props.error.errorField === 'petGender'}
              required
              style={{paddingTop: 16}}
            >
              <InputLabel shrink>Género</InputLabel>
              <Select
                value={this.props.pet.petGender}
                onChange={(event) => this.props.actions.pet.setField('petGender', event.target.value)}
              >
                <MenuItem value="M">Macho</MenuItem>
                <MenuItem value="H">Hembra</MenuItem>
              </Select>
              {
                this.props.error.errorField === 'petGender' ?
                  <FormHelperText>{this.props.error.errorMessage}</FormHelperText>
                : null
              }
            </FormControl>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={this.props.error.errorField === 'HealthPlanId'}
              required
              style={{paddingTop: 16}}
            >
              <InputLabel shrink>Plan</InputLabel>
              <Select
                value={this.props.pet.HealthPlanId}
                onChange={(event) => this.props.actions.pet.setField('HealthPlanId', event.target.value)}
              >
                {
                  this.props.healthPlans && this.props.healthPlans.map((_plan) => {
                    return (<MenuItem key={`petHealthPlan_${_plan.id}`} value={_plan.id}>{`${_plan.healthPlanName} ($${_plan.healthPlanPrice})`}</MenuItem>)
                  })
                }
              </Select>
              {
                this.props.error.errorField === 'HealthPlanId' ?
                  <FormHelperText>{this.props.error.errorMessage}</FormHelperText>
                : null
              }
            </FormControl>
          </Grid>
        </Grid>
        <div className='signupDeletePet'>
          <Button variant="contained" onClick={() => this.props.actions.pet.addPet()}>Afiliar mascota al plan</Button>
        </div>
        <Grid container spacing={16} style={{paddingLeft: 24, paddingRight: 24}}>
          {
            this.props.pets && this.props.pets.map((_pet,_index) => {
              let _planIndex = this.props.healthPlans.findIndex((_plan) => _plan.id === _pet.HealthPlanId);
              let _planName = '';
              if (_planIndex > -1) {
                _planName = this.props.healthPlans[_planIndex].healthPlanName;
              } else {
                _planName = 'Sin Plan';
              }
              return (
                <Chip
                  key={`petItem_${_index}`}
                  icon={<PetsIcon />}
                  label={`${_pet.petName} - ${_planName}`}
                  onDelete={() => this.props.actions.pet.removePet(_index)}
                  color="secondary"
                  style={{margin: 4}}
                />
              );
            })
          }
        </Grid>
        <div className='signUpPetsPrice'>{`Total cuota mensual: $${this.props.totalPrice}`}</div>
      </SignupContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.pet.error,
    pet: state.pet.pet,
    species: state.pet.species,
    breeds: state.pet.breeds,
    healthPlans: state.pet.healthPlans,
    pets: state.pet.pets,
    totalPrice: state.pet.totalPrice
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pet: bindActionCreators(petActions, dispatch),
      message: bindActionCreators(messageActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Pets));

/*
<FormControl fullWidth style={{paddingTop: 16}}>
  <InputLabel shrink id="label">Edad</InputLabel>
  <InputMask
    mask='99/99/9999'
    style={{width:'100%'}}
    value={this.props.pet.petBirthDate}
    onChange={(event) => this.props.actions.pet.setField('petBirthDate', event.target.value)}
  >
    <TextField
      error={this.props.error.errorField === 'petBirthDate'}
      helperText={this.props.error.errorField === 'petBirthDate' ? this.props.error.errorMessage : null}
      placeholder='DD/MM/AAAA'
      margin="normal"
      fullWidth={true}
      required
    />
  </InputMask>
</FormControl>
*/
