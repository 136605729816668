import { SELLERS_EDITOR_UPDATE_FIELD, SELLERS_LIST_SET_SELECTED_SELLER} from '../constants/actions';

const _initSeller={id: 0, sellerName: "", sellerStatus: "A"}

export default function sellersEditor(state = _initSeller, action) {
  let _state= {...state};
  switch (action.type) {
    case SELLERS_EDITOR_UPDATE_FIELD:
      _state[action.value.attr] = action.value.value;
      return _state;
    case SELLERS_LIST_SET_SELECTED_SELLER:
      return action.value?action.value:_initSeller;
    default:
      return state;
  }
}
