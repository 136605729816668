import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Fab from '@material-ui/core/Fab';
import RefreshIcon from '@material-ui/icons/Refresh';
import Container from './container';
import * as ExportActions from '../actions/exportCSV';
import * as ContainerActions from '../actions/container';
import VirtualizedTable from './virtualizedTable';

class AdminExportCSVPage extends Component {

  updateDimensions() {
    var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
        this.props.actions.Export.updateHeight(height - 220);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));

    }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  JSONToCSVConvertor(JSONData, fileName, ShowLabel) {
      const ROW_DELIMITER='\r\n';
      const FIELD_DELIMITER=';';
      const FIELD_ENCLOSER='"';
      var row = "";

      if(!JSONData.length)
        return;
      //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
      var CSV = '';
      //This condition will generate the Label/Header
      if (ShowLabel) {
          row = "";
          //This loop will extract the label from 1st index of on array
          for (var index in arrData[0]) {
              //Now convert each value to string and comma-seprated
              row += FIELD_ENCLOSER +index + FIELD_ENCLOSER + FIELD_DELIMITER;
          }
          row = row.slice(0, -1);
          //append Label row with line break
          CSV += row + ROW_DELIMITER;
      }
      //1st loop is to extract each row
      for (var i = 0; i < arrData.length; i++) {
          row = "";
          //2nd loop will extract each column and convert it in string comma-seprated
          for (var indexField in arrData[i]) {
              row +=  FIELD_ENCLOSER + arrData[i][indexField] + FIELD_ENCLOSER + FIELD_DELIMITER;
          }
          //row.slice(0, row.length - 1);
          row = row.slice(0, -1);
          //add a line break after each row
          CSV += row + ROW_DELIMITER;
      }
      if (CSV === '') {
          alert("Invalid data");
          return;
      }
      // Function to download data to a file
      var download = function(data, filename, type) {
          var file = new Blob([data], {type: type});
          if (window.navigator.msSaveOrOpenBlob) // IE10+
              window.navigator.msSaveOrOpenBlob(file, filename);
          else { // Others
              var a = document.createElement("a"),
                      url = URL.createObjectURL(file);
              a.href = url;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
              setTimeout(function() {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);
              }, 0);
          }
      }

      download(CSV,fileName,'text/csv');
}

  exportToCSV(data){
    const _csvData = this.props.data.filter((item) => item.filename === data.filename);
    if(_csvData && _csvData.length)
      this.JSONToCSVConvertor(_csvData[0].data,data.filename,true);
  }

  render() {
    let _this=this;
    let _exportFiles=[];

    this.props.data.map((_file)=>{
      _exportFiles.push({filename:_file.filename});
      return null;
    })

    return (
      <Container {...this.props}>
        <div style={{height: this.props.height}}>
          <div className='adminTitle'>Exportación</div>
          <VirtualizedTable
            rowCount={_exportFiles.length}
            rowGetter={({ index }) => _exportFiles[index]}
            onRowClick={(event) => _this.exportToCSV(event.rowData)}
            columns={[
              { width: 120, flexGrow: 1.0, label: 'Archivo', dataKey: 'filename' },
            ]}
          />

          <Fab key='refresh' size="small" color="secondary" aria-label="Refresh" style={{position: 'fixed', bottom: '110px', right: '20px'}} onClick={() => this.props.actions.Export.getExportData(this.props.history)}>
            <RefreshIcon />
          </Fab>

        </div>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    height: state.exportCSV.height,
    roleName: state.login.roleName,
    data:state.exportCSV.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      Export: bindActionCreators(ExportActions, dispatch),
      Container: bindActionCreators(ContainerActions, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminExportCSVPage);
