import { SPECIES_LIST_SET_HEIGHT, SPECIES_LIST_SET_LIST, SPECIES_LIST_SET_SELECTED_SPECIE } from '../constants/actions';

export default function newsList(state = {height:0,species:[],selectedSpecie:null}, action) {
  switch (action.type) {
    case SPECIES_LIST_SET_HEIGHT:
      return {...state, height: action.value};
    case SPECIES_LIST_SET_LIST:
      return {...state, species: action.value};
    case SPECIES_LIST_SET_SELECTED_SPECIE:
      return {...state, selectedSpecie: action.value};
    default:
      return state;
  }
}
