import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import ExtendedVirtualizedTable from './extendedVirtualizedTable';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Container from './container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import MODULES from '../constants/modules';

import * as UsersListActions from '../actions/usersList';
import * as ContainerActions from '../actions/container';
import _ from 'underscore';

class AdminUsersPage extends Component {

  rowConvert(rows, index, history) {
    const row = rows[index];
    let convertedRow = {};
    Object.keys(row).map((key) => {
      if (key === 'userPaymentDate') {
        if (row[key] === null) {
          if (this.props.roleName !== 'ADMIN' || ( this.props.roleName === 'ADMIN' && (( this.props.access & MODULES.USERS.level ) || (( this.props.modules & MODULES.PAYMENT.level ) && ( this.props.access & MODULES.PAYMENT.level )))))
            convertedRow.userPaymentDate = { type: 'button', value: (this.props.roleName === 'USER' ? 'Pagar' : 'Registro'), action: () => this.props.actions.UsersList.setPayment(row.data.id) };
          else
          convertedRow[key] = { type: 'text', value: 'IMPAGO', action: (event) => this.props.actions.UsersList.selectUser(row.data, history) };
        } else {
          convertedRow[key] = { type: 'text', value: moment(row[key]).format('DD/MM/YYYY'), action: (event) => this.props.actions.UsersList.selectUser(row.data, history) };
        }
      } else {
        convertedRow[key] = { type: 'text', value: row[key], action: (event) => this.props.actions.UsersList.selectUser(row.data, history) };
      }
      return null;
    });
    return convertedRow;
  }

  updateDimensions() {
    var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
    this.props.actions.UsersList.updateHeight(height - 64);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.updateDimensions();
    this.props.actions.UsersList.getInitialData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {

    let rows = this.props.users.map((_user) => {
      return ({
        userName: _user.userName?_user.userName:'---',
        userEmail: _user.userEmail?_user.userEmail:'---',
        userLastName: _user.userLastName? _user.userLastName:'---',
        userIdNumber: _user.userIdNumber?_user.userIdNumber:'---',
        userPhone: _user.userPhone?_user.userPhone:'---',
        userReferralCode:_user.userReferralCode?_user.userReferralCode:'---',
        userState:_user.StateId?_user.State.stateName:'---',
        userCity:_user.CityId?_user.City.cityName:'---',
        sellerName:_user.SellerId && _user.Seller ?_user.Seller.sellerName:'---',
        createdAt:moment(_user.createdAt).format("DD/MM/YYYY"),
        userPaymentDate:_user.Pets && _user.Pets.length > 0 && _user.Pets.reduce((total, pet) => { return (total + (pet.Payment && pet.Payment.paymentStatus === 'APPROVED' ? 1 : 0)); }, 0) === _user.Pets.length ? _user.Pets.reduce((max, pet) => { return pet.Payment && pet.Payment.paymentStatus === 'APPROVED' && moment(pet.Payment.updatedAt).diff(moment(max)) > 0 ? pet.Payment.updatedAt : max; }, moment('1970-01-01 00:00:00').toDate()) : null,
        data: _user
      });
    });

    rows = _.sortBy(rows, x => -moment(x.data.createdAt).format("YYYYMMDDHHmmSS") );

    return (
      <Container {...this.props}>
        <div style={{height: this.props.height}}>
          <div className='adminTitle'>Listado de Usuarios</div>
          <Grid container style={{paddingLeft: 16, paddingRight: 16, backgroundColor: '#eee'}}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                autoFocus
                margin="normal"
                InputLabelProps={{ shrink: true, }}
                label='Buscar'
                value={this.props.usersFilterValue}
                onChange={(e) => this.props.actions.UsersList.updateFilter({attr:'usersFilterValue', value:e.target.value})}
                onKeyDown={(e) => e.keyCode === 13 ? this.props.actions.UsersList.getInitialData() : null}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl
                fullWidth
                autoFocus
                style={{marginTop: 16}}
              >
                <InputLabel shrink>Atributo</InputLabel>
                <Select
                  value={this.props.usersFilterType}
                  onChange={(event) => this.props.actions.UsersList.updateFilter({attr:'usersFilterType', value:event.target.value})}
                >
                  <MenuItem key={`usersFilterType_0`} value={0}>Nombre y Apellido</MenuItem>
                  <MenuItem key={`usersFilterType_1`} value={1}>DNI</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <ExtendedVirtualizedTable
            rowCount={rows.length}
            rowGetter={({ index }) => this.rowConvert(rows, index, this.props.history)}
            columns={[
              { width: 120, flexGrow: 1.0, label: 'Nombre', dataKey: 'userName' },
              { width: 120, flexGrow: 1.0, label: 'Apellido', dataKey: 'userLastName' },
              { width: 60, flexGrow: 1.0, label: 'DNI', dataKey: 'userIdNumber' },
              { width: 160, flexGrow: 1.0, label: 'Email', dataKey: 'userEmail' },
              { width: 120, flexGrow: 1.0, label: 'Provincia', dataKey: 'userState' },
              { width: 120, flexGrow: 1.0, label: 'Localidad', dataKey: 'userCity' },
              { width: 60, flexGrow: 1.0, label: 'Teléfono', dataKey: 'userPhone' },
              { width: 80, flexGrow: 1.0, label: 'Asesor', dataKey: 'sellerName' },
              { width: 60, flexGrow: 1.0, label: 'Registro Web', dataKey: 'createdAt' },
              { width: 60, flexGrow: 1.0, label: 'Pago', dataKey: 'userPaymentDate' },
            ]}
          />
        </div>
        <Fab color='secondary' size='medium' style={{position: 'fixed', bottom: 60, right: 20}}>
          <AddIcon onClick={() => this.props.actions.UsersList.selectUser(null,this.props.history)}/>
        </Fab>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    height: state.usersList.height,
    users: state.usersList.users,
    selectedUser: state.usersList.selectedUser,
    usersFilterType: state.usersList.usersFilterType,
    usersFilterValue: state.usersList.usersFilterValue,
    modules: state.login.modules,
    access: state.login.access,
    roleName: state.login.roleName
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      UsersList: bindActionCreators(UsersListActions, dispatch),
      Container: bindActionCreators(ContainerActions, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersPage);
