import { PETS_LIST_SET_HEIGHT, PETS_LIST_SET_LIST, PETS_LIST_SET_SELECTED_PET, PETS_LIST_UPDATE_FILTER } from '../constants/actions';

export default function newsList(state = {height:0,pets:[],selectedPet:null, petsFilterType: 2, petsFilterValue: ''}, action) {
  switch (action.type) {
    case PETS_LIST_SET_HEIGHT:
      return {...state, height: action.value};
    case PETS_LIST_SET_LIST:
      return {...state, pets: action.value};
    case PETS_LIST_SET_SELECTED_PET:
      return {...state, selectedPet: action.value};
    case PETS_LIST_UPDATE_FILTER:
      switch (action.value.attr) {
        case 'petsFilterType':
          return {...state, petsFilterType: action.value.value};
        case 'petsFilterValue':
          return {...state, petsFilterValue: action.value.value};
        default:
          return state;
      }
    default:
      return state;
  }
}
