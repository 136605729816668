import {
  USER_RESET,
  USER_SET_NAME,
  USER_SET_LASTNAME,
  USER_SET_EMAIL,
  USER_SET_CELLPHONE,
  USER_SET_PASSWORD,
  USER_SET_REPEAT_PASSWORD,
  USER_SET_ID_NUMBER,
  USER_SET_REFERRAL_CODE,
  USER_SET_STATUS,
  USER_SET_TERMS_ACCEPTANCE_DATE,
  USER_SET_STATE_ID,
  USER_SET_CITY_ID,
  USER_SET_COUPON_ID,
  USER_SET_ROLE_ID,
  USER_SET_ID,
  USER_SET_ERROR,
  USER_SET_STATES,
  USER_SET_CITIES,
  USER_SET_EMAIL_MP,
  USER_SET_CREDIT_CARD_OWNER,
  USER_SET_CREDIT_CARD_NUMBER,
  USER_SET_SUBSCRIPTION_METHOD,
  USER_SET_CREDIT_CARD_OWNER_ID,
  USER_SET_CREDIT_CARD_OWNER_TAX_ID,
  USER_SET_CREDIT_CARD_DUE_DATE,
  USER_SET_SELLERS,
  USER_SET_SELLER_ID
} from '../constants/actions';

const INITAL_STATE = {
  user: {
    id: 0,
    userEmail: '',
    userName: '',
    userLastName: '',
    userPhone: '',
    userPassword: '',
    userRepeatPassword: '',
    userIdNumber: '',
    userReferralCode: '',
    userStatus: 'A',
    userTermsAcceptanceDate: null,
    StateId: '',
    CityId: '',
    SellerId: 1,
    RoleId: 3,
    userSubscriptionMethod:'N',
    userEmailMP: '',
    userCreditCardNumber: '',
    userCreditCardOwnerFullName: '',
    userCreditCardOwnerIdNumber: '',
    CouponId: null,
    userCreditCardOwnerTaxIdNumber: '',
    userCreditCardDueDate: '',
    thinkSoftRegistrationStatus: 'N',
    thinkSoftRegistrationComment: ''
  },
  error: {
    errorField: null,
    errorMessage: ''
  },
  states: [],
  cities: [],
  sellers: []
};

export default function overlay(state = {...INITAL_STATE}, action) {
  let _user = {...state.user};
  let _error = {...state.error};
  switch (action.type) {
  case USER_RESET:
    return {...INITAL_STATE};
  case USER_SET_ID:
    _user.id = action.value;
    return {...state, user: {..._user}};
  case USER_SET_NAME:
    _user.userName = action.value;
    return {...state, user: {..._user}};
  case USER_SET_LASTNAME:
    _user.userLastName = action.value;
    return {...state, user: {..._user}};
  case USER_SET_CELLPHONE:
    _user.userPhone = action.value;
    return {...state, user: {..._user}};
  case USER_SET_PASSWORD:
    _user.userPassword = action.value;
    return {...state, user: {..._user}};
  case USER_SET_REPEAT_PASSWORD:
    _user.userRepeatPassword = action.value;
    return {...state, user: {..._user}};
  case USER_SET_ID_NUMBER:
    _user.userIdNumber = action.value;
    return {...state, user: {..._user}};
  case USER_SET_STATE_ID:
    _user.StateId = action.value;
    return {...state, user: {..._user}};
  case USER_SET_CITY_ID:
    _user.CityId = action.value;
    return {...state, user: {..._user}};
  case USER_SET_COUPON_ID:
    _user.CouponId = action.value;
    return {...state, user: {..._user}};
  case USER_SET_SELLER_ID:
    _user.SellerId = action.value;
    return {...state, user: {..._user}};
  case USER_SET_SUBSCRIPTION_METHOD:
    _user.userSubscriptionMethod = action.value;
    switch (action.value) {
      case 'N':
        _user.userCreditCardNumber = '';
        _user.userCreditCardOwnerFullName = '';
        _user.userCreditCardOwnerIdNumber = '';
        _user.userEmailMP = '';
        _user.userCreditCardOwnerTaxIdNumber = '';
        _user.userCreditCardDueDate = '';
        break;
      case 'M':
        _user.userCreditCardNumber = '';
        _user.userCreditCardOwnerFullName = '';
        _user.userCreditCardOwnerIdNumber = '';
        _user.userCreditCardOwnerTaxIdNumber = '';
        _user.userCreditCardDueDate = '';
        break;
      case 'C':
      case 'S':
        _user.userEmailMP = '';
        break;
      default:
    }
    return {...state, user: {..._user}};
  case USER_SET_EMAIL_MP:
    _user.userEmailMP = action.value;
    return {...state, user: {..._user}};
  case USER_SET_CREDIT_CARD_NUMBER:
    _user.userCreditCardNumber = action.value;
    return {...state, user: {..._user}};
  case USER_SET_CREDIT_CARD_OWNER:
    _user.userCreditCardOwnerFullName = action.value;
    return {...state, user: {..._user}};
  case USER_SET_CREDIT_CARD_OWNER_ID:
    _user.userCreditCardOwnerIdNumber = action.value;
    return {...state, user: {..._user}};
  case USER_SET_CREDIT_CARD_OWNER_TAX_ID:
    _user.userCreditCardOwnerTaxIdNumber = action.value;
    return {...state, user: {..._user}};
  case USER_SET_CREDIT_CARD_DUE_DATE:
    _user.userCreditCardDueDate = action.value;
    return {...state, user: {..._user}};
  case USER_SET_REFERRAL_CODE:
    _user.userReferralCode = action.value;
    return {...state, user: {..._user}};
  case USER_SET_EMAIL:
    _user.userEmail = action.value;
    return {...state, user: {..._user}};
  case USER_SET_STATUS:
    _user.userStatus = action.value;
    return {...state, user: {..._user}};
  case USER_SET_TERMS_ACCEPTANCE_DATE:
    _user.userTermsAcceptanceDate = action.value;
    return {...state, user: {..._user}};
  case USER_SET_ROLE_ID:
    _user.RoleId = action.value;
    return {...state, user: {..._user}};
  case USER_SET_ERROR:
    _error.errorField = action.field;
    _error.errorMessage = action.message;
    return {...state, error: {..._error}};
  case USER_SET_STATES:
    return {...state, states: [...action.values]};
  case USER_SET_CITIES:
    return {...state, cities: [...action.values]};
  case USER_SET_SELLERS:
    return {...state, sellers: [...action.values]};
  default:
    return state;
  }
}
