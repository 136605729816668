import { EXPORTCSV_SET_DATA,  EXPORTCSV_LIST_SET_HEIGHT} from '../constants/actions';

export default function report(state = {height:220,data:[]}, action) {
  switch (action.type) {
    case EXPORTCSV_LIST_SET_HEIGHT:
      return {...state, height: action.value};
    case EXPORTCSV_SET_DATA:
      return {...state, data: action.value};
    default:
      return state;
  }
}
