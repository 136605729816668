import { LOGIN_SET_EMAIL, LOGIN_SET_PWD, LOGIN_CLEAN_DATA, LOGIN_SET_DATA, LOGIN_OPEN_FORGOT_DIALOG, LOGIN_CLOSE_FORGOT_DIALOG } from '../constants/actions';
const INITIAL_STATE = {userId:'', email:'', pwd:'', roleName: 'USER', modules: 0, access: 0, token: null, openForgotDialog: false};
export default function login(state = {...INITIAL_STATE}, action) {
  switch (action.type) {
  case LOGIN_SET_EMAIL:
    return {...state, email: action.value};
  case LOGIN_SET_PWD:
    return {...state, pwd: action.value};
  case LOGIN_SET_DATA:
    return {...state, token: action.value.token, roleName:action.value.roleName, pwd:'', userName:action.value.userName, userId:action.value.userId, modules: action.value.modules, access: action.value.access };
  case LOGIN_OPEN_FORGOT_DIALOG:
    return {...state, openForgotDialog: true};
  case LOGIN_CLOSE_FORGOT_DIALOG:
    return {...state, openForgotDialog: false};
  case LOGIN_CLEAN_DATA:
    return {...INITIAL_STATE};
  default:
    return state;
  }
}
