import {
  TERMS_SET_POST_VALIDATION,
  TERMS_SET_PREEXISTANCES,
  TERMS_SET_DEFICENCIES,
  TERMS_SET_ACCEPTANCE,
  TERMS_SET_ERROR
} from '../constants/actions';

const INIT_STATE = {
  termPostValidation: false,
  termsPreExistances: false,
  termsDeficencies: false,
  termsAcceptance: false,
  error: {
    errorField: null,
    errorMessage: null
  }
}

export default function overlay(state = INIT_STATE, action) {
  let _error = {...state.error};
  switch (action.type) {
  case TERMS_SET_POST_VALIDATION:
    return {...state, termPostValidation: action.value};
  case TERMS_SET_PREEXISTANCES:
    return {...state, termsPreExistances: action.value};
  case TERMS_SET_DEFICENCIES:
    return {...state, termsDeficencies: action.value};
  case TERMS_SET_ACCEPTANCE:
    return {...state, termsAcceptance: action.value};
  case TERMS_SET_ERROR:
    _error.errorField = action.field;
    _error.errorMessage = action.message;
    return {...state, error: {..._error}};
  default:
    return state;
  }
}
