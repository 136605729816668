import { HEALTHPLANS_LIST_SET_HEIGHT, HEALTHPLANS_LIST_SET_LIST, HEALTHPLANS_LIST_SET_SELECTED_HEALTHPLAN } from '../constants/actions';

export default function newsList(state = {height:0,healthPlans:[],selectedHealthPlan:null}, action) {
  switch (action.type) {
    case HEALTHPLANS_LIST_SET_HEIGHT:
      return {...state, height: action.value};
    case HEALTHPLANS_LIST_SET_LIST:
      return {...state, healthPlans: action.value};
    case HEALTHPLANS_LIST_SET_SELECTED_HEALTHPLAN:
      return {...state, selectedHealthPlan: action.value};
    default:
      return state;
  }
}
