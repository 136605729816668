const values = {
  NONE: { level: 0, name: 'Ninguno', hidden: true },
  PROFILE: { level: 1, name: 'Perfil', system: true },
  USERS: { level: 2, name: 'Usuarios' },
  PETS: { level: 4, name: 'Mascotas' },
  STATES: { level: 8, name: 'Provincias' },
  CITIES: { level: 16, name: 'Ciudades' },
  SPECIES: { level: 32, name: 'Especies' },
  BREEDS: { level: 64, name: 'Razas' },
  HEALTHPLANS: { level: 128, name: 'Planes' },
  SELLERS: { level: 256, name: 'Asesores' },
  COUPONS: { level: 512, name: 'Cupones' },
  EXPORT: { level: 1024, name: 'Exportación' },
  PAYMENT: { level: 2048, name: 'Pagos' }
}

export default values;
