import { SELLERS_LIST_SET_HEIGHT, SELLERS_LIST_SET_LIST, SELLERS_LIST_SET_SELECTED_SELLER } from '../constants/actions';

export default function newsList(state = {height:0,sellers:[],selectedSeller:null}, action) {
  switch (action.type) {
    case SELLERS_LIST_SET_HEIGHT:
      return {...state, height: action.value};
    case SELLERS_LIST_SET_LIST:
      return {...state, sellers: action.value};
    case SELLERS_LIST_SET_SELECTED_SELLER:
      return {...state, selectedSeller: action.value};
    default:
      return state;
  }
}
