import * as types from '../constants/actions';

export function show(message) {
  return {
    type: types.MESSAGE_SHOW,
    value: message
  }
}

export function hide() {
  return {
    type: types.MESSAGE_HIDE
  }
}
