import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Container from './container';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import PawIcon from '@material-ui/icons/Pets';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import RetryIcon from '@material-ui/icons/Refresh';
import PwdIcon from '@material-ui/icons/Https';
import PaymentIcon from '@material-ui/icons/Payment';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import * as UsersEditorActions from '../actions/usersEditor';
import * as PaymentActions from '../actions/payment';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import * as MessageActions from '../actions/message';
import _ from "underscore";
import MODULES from '../constants/modules';

class UserEditor extends Component {

  componentDidMount() {
    if (this.props.match.params.id)
      this.props.actions.usersEditor.getInitialData(this.props.match.params.id);
    else
      this.props.actions.usersEditor.getInitialData();
  }

  showUserChangePwdDialog(value){
    this.props.actions.usersEditor.showUserChangePwdDialog(value);
  }

  showPaymentDialog(value){
    this.props.actions.usersEditor.showPaymentDialog(value);
  }

  confirmChangePassword(){
    if(this.props.userOldPass.trim()==='')
      this.props.actions.message.show("Error al ingresar la clave actual");
    else if( this.props.userNewPass.trim()==='' || this.props.userNewPass!==this.props.userNewPassVerified )
      this.props.actions.message.show("Error al ingresar la nueva clave");
    else
      this.props.actions.usersEditor.changeUserPassword(this.props.history);
  }

  preparePaymentDialog() {
    this.props.actions.payment.setPriceToPay(this.props.id, 0);
    this.props.actions.payment.setCouponText('');
    this.showPaymentDialog(true);
  }

  createPreference(userId) {
    this.props.actions.payment.createPreferenceFromScratch(userId);
    this.props.actions.usersEditor.showPaymentDialog(false);
  }
  
  handlePermissionChange(event) {
    this.props.actions.usersEditor.changePermissions(parseInt(event.target.value.split(',')[0]),event.target.value.split(',')[1]);
  }

  render() {
    const readOnly = (((this.props.access & MODULES.USERS.level) === 0 && this.props.roleName !== 'SYSADMIN') || this.props.roleName === 'USER');
    let _title= (this.props.id > 0 ?'Edición de usuario':' Alta de usuario');
    const _sortedStates = _.sortBy(this.props.States, x => x.stateName);
    const _filteredCities = _.sortBy(this.props.Cities.filter(x => x.StateId === this.props.StateId), x => x.cityName);
    const _sortedSellers = _.sortBy(this.props.Sellers, x => x.sellerName);
    const _bottomFABs = [];

    if (this.props.id && this.props.id === this.props.userId) {
      _bottomFABs.push(
        <Fab key='userPassword' size="small" color="secondary" style={{position: 'fixed', bottom: (60 + (50 * _bottomFABs.length)), right: 20 }} aria-label="Pets" onClick={() => this.showUserChangePwdDialog(true)}>
          <PwdIcon />
        </Fab>
      );
    }
  
    if (!readOnly) {
      if (this.props.id !== this.props.userId) {
        _bottomFABs.push(
          <Fab key='userDelete' size="small" color="secondary" style={{position: 'fixed', bottom: (60 + (50 * _bottomFABs.length)), right: 20 }} aria-label="Delete" onClick={() => this.props.actions.usersEditor.deleteUser(this.props.history)}>
            <DeleteIcon />
          </Fab>
        )
      }
      _bottomFABs.push(
        <Fab key='userSave' size="small" color="secondary" style={{position: 'fixed', bottom: (60 + (50 * _bottomFABs.length)), right: 20 }} aria-label="Save" onClick={() => this.props.actions.usersEditor.saveUser(this.props.history)}>
          <SaveIcon />
        </Fab>
      );  
      if (this.props.id>0) {
        _bottomFABs.push(
          <Fab key='userPet' size="small" color="secondary" style={{position: 'fixed', bottom: (60 + (50 * _bottomFABs.length)), right: 20 }} aria-label="Pets" onClick={() => this.props.history.push(`/admin-pets/${this.props.id}`)}>
            <PawIcon />
          </Fab>
        );
        _bottomFABs.push(
          <Fab key='userSubscriptionForm' size="small" color="secondary" style={{position: 'fixed', bottom: (60 + (50 * _bottomFABs.length)), right: 20 }} aria-label="SubscriptionForm" onClick={() => this.props.actions.usersEditor.downloadSubscriptionForm()}>
            <PaymentIcon />
          </Fab>
        );
      }
    }

    if (this.props.Pets && this.props.Pets.length && this.props.Pets.filter(_pet => _pet.Payment && _pet.Payment.paymentStatus === 'APPROVED').length !== this.props.Pets.length) {
      _bottomFABs.push(
        <Fab key='userPayment' size="small" color="secondary" style={{position: 'fixed', bottom: (60 + (50 * _bottomFABs.length)), right: 20 }} aria-label="userPayment" onClick={() => this.preparePaymentDialog()}>
          <MoneyIcon />
        </Fab>
      );
    }

    let _modules = Object.keys(MODULES).map(_module => {
      if (MODULES[_module].hidden || MODULES[_module].system) {
        return null;
      } else {
        return (
          <tr key={`module_${_module}`} disabled={readOnly}>
            <td style={{color: readOnly?'rgba(0, 0, 0, 0.38)':'black'}}>{MODULES[_module].name}</td>
            <td style={{textAlign: 'center'}}><Radio checked={(MODULES[_module].level & this.props.userModules) === 0} onChange={(e) => this.handlePermissionChange(e)} value={`${MODULES[_module].level},locked`} name="radio-button-demo" inputProps={{ 'aria-label': 'profile locked' }}  disabled={readOnly}/></td>
            <td style={{textAlign: 'center'}}><Radio checked={(MODULES[_module].level & this.props.userModules) === MODULES[_module].level && (MODULES[_module].level & this.props.userPermissions) === 0} onChange={(e) => this.handlePermissionChange(e)} value={`${MODULES[_module].level},readonly`} name="radio-button-demo" inputProps={{ 'aria-label': 'profile read only' }}  disabled={readOnly}/></td>
            <td style={{textAlign: 'center'}}><Radio checked={(MODULES[_module].level & this.props.userModules) === MODULES[_module].level && (MODULES[_module].level & this.props.userPermissions) === MODULES[_module].level} onChange={(e) => this.handlePermissionChange(e)} value={`${MODULES[_module].level},full`} name="radio-button-demo" inputProps={{ 'aria-label': 'profile full access' }}  disabled={readOnly}/></td>
          </tr>
        );
      }
    });

    _modules = _modules.filter(x => x!== null);

    return (
      <Container {...this.props}>
        <div className='adminTitle'>{_title}</div>
        <Grid container spacing={2} style={{paddingLeft: 16, paddingRight: 16, marginBottom: 48}}>
          <Grid container>
            {
              this.props.roleName === 'USER' ?
                null
              :
                (
                  this.props.id > 0 ?
                    <div style={{color: ['N','F'].includes(this.props.thinkSoftRegistrationStatus) ? 'crimson' : 'forestgreen', marginTop: ['N','F'].includes(this.props.thinkSoftRegistrationStatus) ? 10 : 0 }}>
                      {['N'].includes(this.props.thinkSoftRegistrationStatus) ? 'Registración en Thinksoft pendiente' : this.props.thinkSoftRegistrationComment}
                      {
                        ['N','F'].includes(this.props.thinkSoftRegistrationStatus) ? 
                          <Fab key='userRetryTS' style={{ marginLeft: 10 }} size="small" color="secondary" aria-label="Pets" onClick={this.props.id > 0 ? () => this.props.actions.usersEditor.doThinkSoftRegistration(this.props.id) : null}>
                            <RetryIcon />
                          </Fab>
                        : null
                      }
                    </div>
                  : null
                )
            }
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Nombre'
              fullWidth={true}
              autoFocus={true}
              value={this.props.userName}
              onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userName', value:e.target.value})}
              disabled={readOnly}
            />
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Apellido'
              fullWidth={true}
              autoFocus={true}
              value={this.props.userLastName}
              onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userLastName', value:e.target.value})}
              disabled={readOnly}
            />
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='DNI'
              fullWidth={true}
              autoFocus={true}
              value={this.props.userIdNumber}
              onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userIdNumber', value:e.target.value})}
              disabled={readOnly}
            />
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Email'
              fullWidth={true}
              autoFocus={true}
              value={this.props.userEmail}
              onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userEmail', value:e.target.value})}
              disabled={readOnly}
            />
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Teléfono'
              fullWidth={true}
              autoFocus={true}
              value={this.props.userPhone}
              onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userPhone', value:e.target.value})}
              disabled={readOnly}
            />
            <FormControl
              fullWidth
              required
              autoFocus
              style={{marginTop: 16}}
              disabled={readOnly}
            >
              <InputLabel shrink>Perfil</InputLabel>
              <Select
                value={this.props.RoleId}
                onChange={(event) => this.props.actions.usersEditor.updateField({attr:'RoleId', value:event.target.value})}
              >
                <MenuItem key={`userEditorRole_2`} value={2}>{'Administrador'}</MenuItem>
                <MenuItem key={`userEditorRole_3`} value={3}>{'Cliente'}</MenuItem>
              </Select>
            </FormControl>
            {
              this.props.RoleId !== 3 ?
                <table className="radioTable">
                  <thead>
                    <tr>
                      <th style={{width: 150, textAlign: 'left'}}>Módulo</th>
                      <th style={{width: 80}}>Bloqueado</th>
                      <th style={{width: 80}}>Lectura</th>
                      <th style={{width: 80}}>Full</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_modules}
                  </tbody>
                </table>
              : null
            }
            <FormControl
              fullWidth
              required
              autoFocus
              style={{marginTop: 24}}
              disabled={readOnly}
            >
              <InputLabel shrink>Provincia</InputLabel>
              <Select
                value={this.props.StateId}
                onChange={(event) => this.props.actions.usersEditor.setSelectedState(event.target.value)}
              >
                <MenuItem key={`userEditorState_0`} value={0}>{'Ninguna'}</MenuItem>
                {
                  _sortedStates && _sortedStates.map((_state) => {
                    return (<MenuItem key={`userEditorState_${_state.id}`} value={_state.id}>{_state.stateName}</MenuItem>)
                  })
                }
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              required
              autoFocus
              style={{marginTop: 24}}
              disabled={readOnly}
            >
              <InputLabel shrink>Ciudad</InputLabel>
              <Select
                value={this.props.CityId}
                onChange={(event) => this.props.actions.usersEditor.setSelectedCity(event.target.value)}
              >
                <MenuItem key={`userEditorCity_0`} value={0}>{'Ninguna'}</MenuItem>
                {
                  _filteredCities.map((_city) => {
                    return (<MenuItem key={`userEditorCity_${_city.id}`} value={_city.id}>{_city.cityName}</MenuItem>)
                  })
                }
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              required
              autoFocus
              style={{marginTop: 24}}
              disabled={readOnly}
            >
              <InputLabel shrink>{this.props.SellerId === null && this.props.userReferralCode !== '' ? `Asesor (anterior: ${this.props.userReferralCode})` : 'Asesor' }</InputLabel>
              <Select
                value={this.props.SellerId}
                onChange={(event) => this.props.actions.usersEditor.updateField({attr:'SellerId', value:event.target.value})}
              >
                {
                  _sortedSellers && _sortedSellers.map((_seller) => {
                    return (<MenuItem key={`userEditorSeller_${_seller.id}`} value={_seller.id}>{_seller.sellerName}</MenuItem>)
                  })
                }
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              required
              autoFocus
              style={{marginTop: 16}}
              disabled={readOnly}
            >
              <InputLabel shrink>Canal de pago elegido</InputLabel>
              <Select
                value={this.props.userSubscriptionMethod}
                onChange={(event) => this.props.actions.usersEditor.updateField({attr:'userSubscriptionMethod', value:event.target.value})}
              >
                <MenuItem key={`userSubscriptionMethod_X`} value={'N'}>{'Pago Directo (Rapipago, Pago Online, PMC)'}</MenuItem>
                <MenuItem key={`userSubscriptionMethod_D`} value={'D'}>{'Tarjeta de débito VISA'}</MenuItem>
                <MenuItem key={`userSubscriptionMethod_M`} value={'C'}>{'Tarjeta de crédito VISA'}</MenuItem>
                <MenuItem key={`userSubscriptionMethod_S`} value={'S'}>{'Tarjeta de crédito MASTERCARD'}</MenuItem>
              </Select>
            </FormControl>
            {
              this.props.userSubscriptionMethod === 'N' ? null
              : this.props.userSubscriptionMethod === 'M' ?
                <TextField
                  margin="normal"
                  InputLabelProps={{ shrink: true, }}
                  label='Email registrado en Mercado Pago'
                  fullWidth={true}
                  autoFocus={true}
                  value={this.props.userEmailMP}
                  onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userEmailMP', value:e.target.value})}
                  disabled={readOnly}
                />
              :
                [
                  <TextField
                    key="_userCreditCardNumber"
                    margin="normal"
                    InputLabelProps={{ shrink: true, }}
                    label='Número de tarjeta'
                    fullWidth={true}
                    autoFocus={true}
                    value={this.props.userCreditCardNumber}
                    onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userCreditCardNumber', value:e.target.value})}
                    disabled={readOnly}
                  />,
                  <TextField
                    key="_userCreditCardOwnerFullName"
                    margin="normal"
                    InputLabelProps={{ shrink: true, }}
                    label='Nombre del titular'
                    fullWidth={true}
                    autoFocus={true}
                    value={this.props.userCreditCardOwnerFullName}
                    onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userCreditCardOwnerFullName', value:e.target.value})}
                    disabled={readOnly}
                  />,
                  <TextField
                    key="_userCreditCardDueDate"
                    margin="normal"
                    InputLabelProps={{ shrink: true, }}
                    label='Vencimiento de la tarjeta (MM/AA)'
                    fullWidth={true}
                    autoFocus={true}
                    value={this.props.userCreditCardDueDate}
                    onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userCreditCardDueDate', value:e.target.value})}
                    disabled={readOnly}
                  />,
                  <TextField
                    key="_userCreditCardOwnerIdNumber"
                    margin="normal"
                    InputLabelProps={{ shrink: true, }}
                    label='DNI del titular'
                    fullWidth={true}
                    autoFocus={true}
                    value={this.props.userCreditCardOwnerIdNumber}
                    onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userCreditCardOwnerIdNumber', value:e.target.value})}
                    disabled={readOnly}
                  />,
                  <TextField
                    key="_userCreditCardOwnerTaxIdNumber"
                    margin="normal"
                    InputLabelProps={{ shrink: true, }}
                    label='CUIT del titular'
                    fullWidth={true}
                    autoFocus={true}
                    value={this.props.userCreditCardOwnerTaxIdNumber}
                    onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userCreditCardOwnerTaxIdNumber', value:e.target.value})}
                    disabled={readOnly}
                  />
                ]
            }
          </Grid>
        </Grid>
        {
          this.props.modules & MODULES.USERS.level ?
            <Fab key='userClose' size="small" color="secondary" aria-label="Discard" style={{position: 'fixed', top: '80px', right: '20px'}} onClick={() => this.props.history.goBack()}>
              <CloseIcon />
            </Fab>
          : null
        }
        {_bottomFABs}
        <Dialog open={this.props.userChangePwdDialog} onClose={()=>this.showUserChangePwdDialog(false)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Cambio de clave</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Para cambiar la clave por favor ingrese su clave actual y su nueva clave.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="userOldPassword"
              label="Clave Actual"
              type="password"
              fullWidth
              value={this.props.userOldPass}
              onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userOldPass', value:e.target.value})}
            />
            <TextField
              margin="dense"
              id="userNewPassword"
              label="Nueva Clave"
              type="password"
              fullWidth
              value={this.props.userNewPass}
              onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userNewPass', value:e.target.value})}
            />
            <TextField
              margin="dense"
              id="userNewPasswordVerified"
              label="Reingrese Nueva Clave"
              type="password"
              fullWidth
              value={this.props.userNewPassVerified}
              onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userNewPassVerified', value:e.target.value})}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>this.showUserChangePwdDialog(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={()=>this.confirmChangePassword()} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.props.userPaymentDialog} onClose={()=>this.showPaymentDialog(false)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Pago</DialogTitle>
          <DialogContent>
            <Grid container className='innerWidthContainer'>
              {
                moment().format('D') >= 15 ?
                  <Grid item xs={12}>
                    <div className='signupPaymentAlertTitle'>IMPORTANTE:</div>
                    <div className='signupPaymentAlertContent'>{`En este acto está abonando la última quincena del mes ${moment().format('M')}.`}</div>
                    <div className='signupPaymentAlertContent'>{`Las carencias comienzan a correr desde el ${moment().format('15/MM/YYYY')}.`}</div>
                    <div className='signupPaymentAlertContent'>{`La cobertura puede utilizarse a partir del ${moment().format('DD/MM/YYYY')}.`}</div>
                  </Grid>
                :
                  <Grid item xs={12}>
                    <div className='signupPaymentAlertTitle'>IMPORTANTE:</div>
                    <div className='signupPaymentAlertContent'>{`Las carencias comienzan a correr desde el ${moment().format('DD/MM/YYYY')}.`}</div>
                    <div className='signupPaymentAlertContent'>{`La cobertura puede utilizarse a partir del ${moment().format('DD/MM/YYYY')}.`}</div>
                  </Grid>
              }
              <Grid item xs={12}>
                <div className='signupPaymentPrice'>{`Total cuota a abonar: $${this.props.totalValue}`}</div>
              </Grid>
              {
                moment().format('D') >= 15 ?
                <Grid item xs={12}>
                  <div className='signupPaymentDiscount'>Tenga en cuenta que en este acto sólo abona la última quincena de este mes, es decir, la mitad del período y del monto de la cuota mensual.<br/>Aclaración: como está pagando medio mes las carencias comienzan a contar también desde la mitad del mes, es decir, a partir del 15.</div>
                </Grid>
                :null
              }
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  InputLabelProps={{ shrink: true, }}
                  label='Cupón de descuento'
                  fullWidth={true}
                  value={this.props.couponText}
                  onChange={(event) => this.props.actions.payment.setCouponText(event.target.value)}
                  disabled={this.props.couponValidated}
                />
              </Grid>
              <Grid item xs={12}>
                {
                  this.props.couponValidated ?
                    <div className='signupPaymentDisclosure'>
                      <br/>{`Usted ha registrado un cupón de descuento que lo hace acreedor a un beneficio de${this.props.coupon.couponPercentageDiscount > 0 ? `l ${this.props.coupon.couponPercentageDiscount}%` : ` $${this.props.coupon.couponMpneyDiscount}`} sobre el importe final a abonar. El mismo ya se encuenta aplicado sobre el valor final.`}<br/><br/>
                    </div>
                  : (
                      this.props.couponText !== '' ?
                        <Button fullWidth variant="contained" color="primary" onClick={() => this.props.actions.payment.validateCoupon(this.props.couponText, this.props.id)} disabled={this.props.couponValidated}>{'Validar Cupón de descuento'}</Button>
                      : null
                    )
                }
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>this.showPaymentDialog(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => this.createPreference(this.props.id)} color="primary">
              Abonar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    id: state.usersEditor.user.id,
    userName: state.usersEditor.user.userName,
    userLastName: state.usersEditor.user.userLastName,
    userIdNumber: state.usersEditor.user.userIdNumber,
    userEmail: state.usersEditor.user.userEmail,
    userPhone: state.usersEditor.user.userPhone,
    userReferralCode: state.usersEditor.user.userReferralCode,
    userSubscriptionMethod: state.usersEditor.user.userSubscriptionMethod,
    userCreditCardNumber: state.usersEditor.user.userCreditCardNumber,
    userCreditCardOwnerFullName: state.usersEditor.user.userCreditCardOwnerFullName,
    userCreditCardOwnerIdNumber: state.usersEditor.user.userCreditCardOwnerIdNumber,
    userCreditCardOwnerTaxIdNumber: state.usersEditor.user.userCreditCardOwnerTaxIdNumber,
    userCreditCardDueDate: state.usersEditor.user.userCreditCardDueDate,
    thinkSoftRegistrationStatus: state.usersEditor.user.thinkSoftRegistrationStatus,
    thinkSoftRegistrationComment: state.usersEditor.user.thinkSoftRegistrationComment,
    userEmailMP: state.usersEditor.user.userEmailMP,
    RoleId: state.usersEditor.user.RoleId,
    modules: state.login.modules,
    access: state.login.access,
    userModules: state.usersEditor.user.userModules,
    userPermissions: state.usersEditor.user.userPermissions,
    userOldPass: state.usersEditor.user.userOldPass,
    userNewPass: state.usersEditor.user.userNewPass,
    userNewPassVerified:state.usersEditor.user.userNewPassVerified,
    userChangePwdDialog:state.usersEditor.userChangePwdDialog,
    userPaymentDialog:state.usersEditor.userPaymentDialog,
    StateId: state.usersEditor.user.StateId,
    CityId: state.usersEditor.user.CityId,
    SellerId: state.usersEditor.user.SellerId,
    States: state.usersEditor.States,
    Cities: state.usersEditor.Cities,
    Sellers: state.usersEditor.Sellers,
    roleName: state.login.roleName,
    userId: state.login.userId,
    Pets: state.usersEditor.user.Pets,
    totalPrice: state.pet.totalPrice,
    totalValue: state.payment.totalValue,
    couponText: state.payment.couponText,
    couponValidated: state.payment.couponValidated,
    coupon: state.payment.Coupon
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      message: bindActionCreators(MessageActions, dispatch),
      usersEditor: bindActionCreators(UsersEditorActions, dispatch),
      payment: bindActionCreators(PaymentActions, dispatch)
    }
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(UserEditor);
