import { CREDENTIAL_RESET, CREDENTIAL_SET } from '../constants/actions';

const INITIAL_STATE = {
  id: 0,
  petName: '',
  createdAt: Date.now(),
  petInternalID: '',
  User: {
    userName:'',
    userLastName:'',
    userIdNumber:''
  },
  Breed: {
    breedName: ''
  },
  HealthPlan: {
    healthPlanName: 'OSPAN'
  }
};

export default function overlay(state = INITIAL_STATE, action) {
  switch (action.type) {
  case CREDENTIAL_RESET:
    return {...INITIAL_STATE};
  case CREDENTIAL_SET:
    return {...action.value};
  default:
    return state;
  }
}
