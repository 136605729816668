import { COUPONS_LIST_SET_HEIGHT, COUPONS_LIST_SET_LIST, COUPONS_LIST_SET_SELECTED_COUPON } from '../constants/actions';

export default function couponsList(state = {height:0,coupons:[],selectedCoupon:null}, action) {
  switch (action.type) {
    case COUPONS_LIST_SET_HEIGHT:
      return {...state, height: action.value};
    case COUPONS_LIST_SET_LIST:
      return {...state, coupons: action.value};
    case COUPONS_LIST_SET_SELECTED_COUPON:
      return {...state, selectedCoupon: action.value};
    default:
      return state;
  }
}
