import { combineReducers } from 'redux';
import login from './login';
import message from './message';
import overlay from './overlay';
import container from './container';
import user from './user';
import pet from './pet';
import couponsList from './couponsList';
import couponsEditor from './couponsEditor';
import statesList from './statesList';
import statesEditor from './statesEditor';
import healthPlansList from './healthPlansList';
import healthPlansEditor from './healthPlansEditor';
import speciesList from './speciesList';
import speciesEditor from './speciesEditor';
import usersList from './usersList';
import petsList from './petsList';
import usersEditor from './usersEditor';
import terms from './terms';
import citiesList from './citiesList';
import citiesEditor from './citiesEditor';
import breedsList from './breedsList';
import breedsEditor from './breedsEditor';
import credential from './credential';
import petsEditor from './petsEditor';
import exportCSV from './exportCSV';
import payment from './payment';
import sellersList from './sellersList';
import sellersEditor from './sellersEditor';

const rootReducer = combineReducers({
  login,
  message,
  overlay,
  container,
  user,
  pet,
  statesList,
  statesEditor,
  couponsList,
  couponsEditor,
  healthPlansList,
  healthPlansEditor,
  speciesList,
  speciesEditor,
  usersList,
  petsList,
  usersEditor,
  terms,
  citiesList,
  citiesEditor,
  breedsList,
  breedsEditor,
  credential,
  petsEditor,
  exportCSV,
  payment,
  sellersList,
  sellersEditor
});

export default rootReducer;
