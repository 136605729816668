import * as types from '../constants/actions';
import fetch from 'isomorphic-fetch';
import async from 'async';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';
import _ from "underscore";

export function getInitialData(history) {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getSpecies = function(data, cb) {
      fetch(_uri + '/api/species',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(species => {
            cb(null,species);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    const getHealthPlans = function(data, cb) {
      fetch(_uri + '/api/healthplans',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(healthplans => {
            cb(null,{species: [...data], healthplans});
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      getSpecies,
      getHealthPlans
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch({type: types.PET_RESET});
          dispatch({type: types.PET_SET_USER_ID, value: getState().user.user.id});
          dispatch({type: types.PET_SET_SPECIES, values: result.species});
          dispatch({type: types.PET_SET_HEALTHPLANS, values: result.healthplans});
        }
    });
  }
}

export function setField(field, value) {
  let _action = null;
  switch (field) {
    case 'petName': _action = types.PET_SET_NAME; break;
    case 'petBirthDate': _action = types.PET_SET_BIRTHDATE; break;
    case 'petGender': _action = types.PET_SET_GENDER_ID; break;
    case 'SpecieId': _action = types.PET_SET_SPECIE_ID; break;
    case 'BreedId': _action = types.PET_SET_BREED_ID; break;
    case 'HealthPlanId': _action = types.PET_SET_HEALTHPLAN_ID; break;
    case 'petPhoto': _action = types.PET_SET_PHOTO; break;
    default:
      _action = null;
  }
  return {
    type: _action,
    value
  }
}

export function getBreeds(specieID) {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getBreeds = function(data, cb) {
      fetch(`${_uri}/api/species/${specieID}/breeds`,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(breeds => {
            cb(null, breeds);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      getBreeds
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch({type: types.PET_SET_BREEDS, values: result.Breeds});
        }
    });
  }
}

export function setSpecieAndGetBreeds(value) {
  return function (dispatch, getState) {
    dispatch(setField('SpecieId', value));
    dispatch(setField('BreedId', ''));
    dispatch(getBreeds(value));
  }
}

export function setError(field, message) {
  return {
    type: types.PET_SET_ERROR,
    field,
    message
  }
}

export function calculateTotalPrice() {
  return function (dispatch, getState) {
    let _total = 0;
    const plans = getState().pet.healthPlans;
    let _pets = [...getState().pet.pets];
    _pets = _pets.map((_pet, _index) => {
      let _planIndex = plans.findIndex((_plan) => _plan.id === _pet.HealthPlanId);
      _pet.healthPlanPrice = parseInt(plans[_planIndex].healthPlanPrice);
      _pet.healthPlanDiscountOrder = plans[_planIndex].healthPlanDiscountOrder;
      return _pet;
    });
    _total = _.reduce(_pets, (memo, _pet) => { return memo + _pet.healthPlanPrice; }, 0);
    if (_pets.length === 0) {
      _total = 0;
    } else if (_pets.length > config.discounts.length) {
      _total = _total * (1 - (config.discounts[config.discounts.length -1].value/100));
    } else {
      _total = _total * (1 - (config.discounts[_pets.length -1].value/100));
    }
    dispatch({type: types.PET_SET_TOTAL_PRICE, value: _total});
  }
}

export function addPet() {

  return function (dispatch, getState) {
    const _pet = getState().pet.pet;

    if (_pet.petName === null || _pet.petName === '') {
      dispatch (setError('petName','El nombre no puede ser vacío')); return;
    }

    if (!_pet.petPhoto || _pet.petPhoto.name === undefined || _pet.petPhoto.name === '') {
      dispatch (setError('petPhoto','Debe agregar la foto de su mascota')); return;
    }

    if (_pet.SpecieID === null || _pet.SpecieID === '') {
      dispatch (setError('SpecieId','Debe seleccionar la especie')); return;
    }
    if (_pet.BreedID === null || _pet.BreedID === '' ) {
      dispatch (setError('BreedId','Debe seleccionar la raza')); return;
    }
    if (_pet.petGender === null || _pet.petGender === '') {
      dispatch (setError('petGender','Debe seleccionar el género')); return;
    }
    if (_pet.HealthPlanId === null || _pet.HealthPlanId === '' ) {
      dispatch (setError('HealthPlanID','Debe seleccionar el plan')); return;
    }
    if (_pet.UserId === null || _pet.UserId === 0) {
      dispatch (setError('petName','Debe registrarse previamente como propietario')); return;
    }
    dispatch(setError(null,''));
    dispatch({type: types.PET_ADD, value: getState().pet.pet});

    dispatch(setField('petName', ''));
    dispatch(setField('petAge', ''));
    dispatch(setField('petPhoto', ''));
    dispatch(setField('petGender', ''));
    dispatch(setField('SpecieId', ''));
    dispatch(setField('BreedId', ''));
    dispatch(setField('HealthPlanId', ''));
    dispatch({type: types.PET_SET_BREEDS, values: []});
    dispatch(calculateTotalPrice());
  }
}

export function removePet(_index) {
  return function (dispatch, getState) {
    dispatch({type: types.PET_REMOVE, value: _index});
    dispatch(calculateTotalPrice());
  };
}

export function savePets(source, history, nextRoute) {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cbStart) {
      dispatch(OverlayActions.show());
      return cbStart(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const validate = function(data, cbValidate) {
      if (getState().pet.pets.length > 0) return cbValidate(null,null);
      else return cbValidate(new Error('Debe afiliar al menos a una mascota'));
    }

    const save = function(_pet, cbSave) {
      let _photo = _pet.petPhoto;
      delete _pet.petPhoto;
      fetch(`${_uri}/api/pets`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(_pet)
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cbSave(new Error(data.message));
            })
          } else {
            cbSave(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(data => {
            const formData  = new FormData();
            formData.append('photo', _photo);
            fetch(`${_uri}/api/pets/${data.id}/photo`, {
              method: 'POST',
              body: formData
            })
            .then(function(response) {
              if (!response.ok) {
                if (response.status < 500) {
                  response.json()
                  .then(data => {
                    console.error(data.message);
                  })
                } else {
                  console.error(response.statusText);
                }
              }
            })
            .catch(error => console.error(JSON.stringify(error)))
            .finally(() => {
              return cbSave(null, null);
            });
          })
        }
      })
      .catch(err => {
        return cbSave(err);
      });
    }

    const savePets = function(data, cbSavePets) {
      async.each(getState().pet.pets, save, function (error,result) {
        if (error) {
          return cbSavePets(error);
        } else {
          return cbSavePets(null, null);
        }
      });
    };

    async.waterfall([
      start,
      validate,
      savePets
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          if (source === 'signup') history.push(nextRoute);
          else history.goBack();
        }
    });
  }
}
