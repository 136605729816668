import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Container from './container';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import * as SellersEditorActions from '../actions/sellersEditor';
import MODULES from '../constants/modules';

class SellerEditor extends Component {
  render() {
    const readOnly = (((this.props.access & MODULES.SELLERS.level) === 0 && this.props.roleName !== 'SYSADMIN') || this.props.roleName === 'USER');
    let _title= (this.props.selectedSeller?'Edición de asesor ':' Alta de asesor')
    + (this.props.selectedSeller?this.props.selectedSeller.sellerName:'');
    return (
      <Container {...this.props}>
        <div className='adminTitle'>{_title}</div>
        <Grid container spacing={16} style={{paddingLeft: 16, paddingRight: 16}}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Nombre'
              fullWidth={true}
              autoFocus={true}
              value={this.props.newSeller.sellerName}
              onChange={(e) => this.props.actions.sellersEditor.updateField({attr:'sellerName', value:e.target.value})}
              disabled={readOnly}
            />
            <FormControl
              fullWidth
              required
              autoFocus
              style={{marginTop: 16}}
            >
              <InputLabel shrink>Estado</InputLabel>
              <Select
                value={this.props.newSeller.sellerStatus}
                onChange={(event) => this.props.actions.sellersEditor.updateField({attr:'sellerStatus', value:event.target.value})}
                disabled={readOnly}
              >
                <MenuItem key={`sellerStatus_active`} value={'A'}>{'Activo'}</MenuItem>
                <MenuItem key={`sellerStatus_inactive`} value={'I'}>{'Inactivo'}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Fab key='sellerClose' size="small" color="secondary" aria-label="Discard" style={{position: 'fixed', top: '80px', right: '20px'}} onClick={() => this.props.history.goBack()}>
          <CloseIcon />
        </Fab>
        {
          readOnly ?
            null
          :
            <Fab key='sellerSave' size="small" color="secondary" aria-label="Save" style={{position: 'fixed', bottom: '110px', right: '20px'}} onClick={() => this.props.actions.sellersEditor.saveSeller(this.props.history)}>
              <SaveIcon />
            </Fab>
        }
        {
          this.props.selectedSeller && !readOnly ?
            <Fab key='sellerDelete' size="small" color="secondary" aria-label="Delete" style={{position: 'fixed', bottom: '60px', right: '20px'}} onClick={() => this.props.actions.sellersEditor.deleteSeller(this.props.history)}>
              <DeleteIcon />
            </Fab>
          :
            null
        }
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedSeller: state.sellersList.selectedSeller,
    newSeller: state.sellersEditor,
    access: state.login.access
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      sellersEditor: bindActionCreators(SellersEditorActions, dispatch),
    }
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(SellerEditor);
