import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import * as OverlayActions from '../actions/overlay';
import * as MessageActions from '../actions/message';

class MainContainer extends Component {
  render() {
    return (
      <div>
      {
        this.props.overlay.open?
        <Dialog
          open={this.props.overlay.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <div style={{textAlign: 'center'}}>
              <CircularProgress color="secondary" />
              <DialogContentText>
                {this.props.overlay.message}
              </DialogContentText>
            </div>
          </DialogContent>
        </Dialog>
        :null
      }
      {this.props.children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.props.message.open}
        autoHideDuration={3000}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        onClose={() => this.props.actions.message.hide()}
        message={<span id="message-id">{this.props.message.value}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.props.actions.message.hide()}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    overlay: state.overlay,
    message: state.message,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      overlay: bindActionCreators(OverlayActions, dispatch),
      message: bindActionCreators(MessageActions, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
