import { BREEDS_LIST_SET_HEIGHT, BREEDS_LIST_SET_LIST, BREEDS_LIST_SET_SELECTED_BREED,BREEDS_LIST_SET_SPECIES,BREEDS_LIST_SET_SELECTED_SPECIE } from '../constants/actions';

export default function breedsList(state = {height:0,breeds:[],species:[],selectedBreed:null,selectedSpecie:0}, action) {
  switch (action.type) {
    case BREEDS_LIST_SET_HEIGHT:
      return {...state, height: action.value};
    case BREEDS_LIST_SET_LIST:
      return {...state,breeds: action.value};
    case BREEDS_LIST_SET_SPECIES:
      return {...state,species: action.value};
    case BREEDS_LIST_SET_SELECTED_BREED:
      return {...state, selectedBreed: action.value};
    case BREEDS_LIST_SET_SELECTED_SPECIE:
      return {...state,selectedSpecie:action.value,};
    default:
      return state;
  }
}
