import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Container from './container';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import * as credentialActions from '../actions/credential';
import config from '../resources/js/config.js';

class Credential extends Component {
  componentDidMount() {
    this.props.actions.credential.getInitialData(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.credential.petName && nextProps.credential.petName !== '') {
      const mi_canvas = document.getElementById("credentialImage");
      const contexto = mi_canvas.getContext("2d");
      let loaded = 0;
      var dibujar_img = function() {
        try {contexto.drawImage(img_tarjeta, 0, 0, 400, 250);} catch (e) {console.log(e)};
        try {contexto.drawImage(img_logo, 20, 20, 120, 40);} catch (e) {console.log(e)};
        try {contexto.drawImage(img_mascota, 260, 104, 120, 120);} catch (e) {console.log(e)};
      }

      var acumular_img = function() {
        if (loaded === 2) {
          dibujar_img();
          const _alturaTextoBase = 0
          contexto.font = "16px Helvetica";
          contexto.fillStyle = "white";
          contexto.textAlign = "left";
          contexto.fillText(`Mascota: ${nextProps.credential && nextProps.credential.petName ? nextProps.credential.petName : ''}`, 20, _alturaTextoBase + 100);
          contexto.fillText(`Propietario: ${nextProps.credential && nextProps.credential.User ? `${nextProps.credential.User.userLastName}, ${nextProps.credential.User.userName}` : ''}`, 20, _alturaTextoBase + 120, 220);
          contexto.fillText(`DNI: ${nextProps.credential && nextProps.credential.User ? nextProps.credential.User.userIdNumber : ''}`, 20, _alturaTextoBase + 140);
          contexto.fillText(`Raza: ${nextProps.credential && nextProps.credential.Breed ? nextProps.credential.Breed.breedName : ''}`, 20, _alturaTextoBase + 160);
          contexto.fillText(`Fecha alta: ${nextProps.credential && nextProps.credential.petSignUpDate ? moment(nextProps.credential.petSignUpDate).format('DD/MM/YYYY')  : ''}`, 20, _alturaTextoBase + 180);
          contexto.fillText(`Plan: ${nextProps.credential && nextProps.credential.HealthPlan ? nextProps.credential.HealthPlan.healthPlanName : ''}`, 20, _alturaTextoBase + 200);
          contexto.fillText(`ID: ${nextProps.credential && nextProps.credential.petInternalID ? nextProps.credential.petInternalID : 'TEMPORAL'}`, 20, _alturaTextoBase + 220);
          let _showPaymentPending = false;
          if (nextProps.credential.Payment && nextProps.credential.Payment.paymentStatus === 'APPROVED') {
            _showPaymentPending = false;
          } else {
            _showPaymentPending = true;
          }
          if (_showPaymentPending) {
            contexto.fillStyle = "pink";
            contexto.fillText(`PAGO PENDIENTE`, 260, 48, 120);
          }
        } else {
          loaded += 1;
        }
      }

      let _patientImg  = `${config.server}/img/${this.props.match.params.id}.jpg?dummy=${Math.floor(Math.random() * 100000)}`;
      let _vetImg      = '/logo-nb-w.png';
      let _credentialBackgroundImg = nextProps.credential && nextProps.credential.HealthPlan ? `/${nextProps.credential.HealthPlan.healthPlanName}.jpg` : `/OSPAN 200.jpg`;

      let img_mascota = new Image();
      img_mascota.crossOrigin = "anonymous";
      img_mascota.src = _patientImg;
      img_mascota.addEventListener('load', acumular_img, false);
      img_mascota.addEventListener('error', acumular_img, false);

      let img_logo = new Image();
      img_logo.crossOrigin = "anonymous";
      img_logo.src = _vetImg;
      img_logo.addEventListener('load', acumular_img, false);
      img_logo.addEventListener('error', acumular_img, false);

      let img_tarjeta = new Image();
      img_tarjeta.crossOrigin = "anonymous";
      img_tarjeta.src =_credentialBackgroundImg;
      img_tarjeta.addEventListener('load', acumular_img, false);
      img_tarjeta.addEventListener('error', acumular_img, false);
    }
  }

  render() {
    return (
      <Container {...this.props}>
        <div className='adminTitle'>Credencial digital</div>
        <Grid container spacing={16} style={{paddingLeft: 8, paddingRight: 8, paddingTop: 40}}>
          <Grid item xs={12}>
            <canvas crossOrigin="anonymous" id={"credentialImage"} width="400" height="250" style={{border:"3px solid #ccc", borderRadius: 30}} />
          </Grid>
          <Grid item xs={12}>
            <Button className='homeButton' variant="contained" color='secondary' style={{width: 406}} onClick={() => window.download_image(this.props.credential.petName)}>Descargar credencial</Button>
          </Grid>
        </Grid>
        <Fab key='petClose' size="small" color="secondary" aria-label="Discard" style={{position: 'fixed', top: '80px', right: '20px'}} onClick={() => this.props.history.goBack()}>
          <CloseIcon />
        </Fab>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    credential: state.credential,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      credential: bindActionCreators(credentialActions, dispatch)
    }
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(Credential);
