import { USERS_LIST_SET_HEIGHT, USERS_LIST_SET_LIST, USERS_LIST_SET_SELECTED_USER, USERS_LIST_UPDATE_FILTER, USERS_LIST_UPDATE_USER_PAYMENT } from '../constants/actions';

export default function newsList(state = {height:0,users:[],selectedUser:null, usersFilterType: 0, usersFilterValue: ''}, action) {
  switch (action.type) {
    case USERS_LIST_SET_HEIGHT:
      return {...state, height: action.value};
    case USERS_LIST_SET_LIST:
      return {...state, users: action.value};
    case USERS_LIST_SET_SELECTED_USER:
      return {...state, selectedUser: action.value};
    case USERS_LIST_UPDATE_FILTER:
      switch (action.value.attr) {
        case 'usersFilterType':
          return {...state, usersFilterType: action.value.value};
        case 'usersFilterValue':
          return {...state, usersFilterValue: action.value.value};
        default:
          return state;
      }
    case USERS_LIST_UPDATE_USER_PAYMENT:
      let _users = [...state.users];
      let _found = false;
      for (let i = 0; i < _users.length && !_found; i++) {
        if (_users[i].id === action.value) {
          _found = true;
          if (_users[i].Pets) {
            for (let iPet = 0; iPet < _users.length; iPet++) {
              if (_users[i].Pets[iPet].Payment) {
                if (_users[i].Pets[iPet].Payment.paymentStatus !== 'APPROVED') {
                  _users[i].Pets[iPet].Payment.paymentStatus = 'APPROVED';
                  _users[i].Pets[iPet].Payment.updatedAt = Date.now();
                }
              } else {
                _users[i].Pets[iPet].Payment = {paymentStatus: 'APPROVED', updatedAt: Date.now()};
              }
            }
          }
        }
      }
      return {...state, users: [..._users]};
    default:
      return state;
  }
}
