import { COUPONS_EDITOR_UPDATE_FIELD, COUPONS_LIST_SET_SELECTED_COUPON} from '../constants/actions';

const _initCoupon={id: 0, couponName: "", couponPercentageDiscount: 0, couponMoneyDiscount: 0, couponStatus: "A"}

export default function couponsEditor(state = _initCoupon, action) {
  let _state= {...state};
  switch (action.type) {
    case COUPONS_EDITOR_UPDATE_FIELD:
      _state[action.value.attr] = action.value.value;
      return _state;
    case COUPONS_LIST_SET_SELECTED_COUPON:
      return action.value?action.value:_initCoupon;
    default:
      return state;
  }
}
