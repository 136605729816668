import { STATE_EDITOR_UPDATE_FIELD, STATES_LIST_SET_SELECTED_STATE} from '../constants/actions';

const _initState={id: 0, stateName: "", stateStatus: "A"}

export default function statesEditor(state = _initState, action) {
  let _state= {...state};
  switch (action.type) {
    case STATE_EDITOR_UPDATE_FIELD:
      _state[action.value.attr] = action.value.value;
      return _state;
    case STATES_LIST_SET_SELECTED_STATE:
      return action.value?action.value:_initState;
    default:
      return state;
  }
}
