import React from 'react';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';

const steps = [
  { id: 1, name: 'Registro', next: 'plans', title: 'INFORMACIÓN DEL TUTOR/RESPONSABLE DE LA MASCOTA'},
  { id: 2, name: 'Planes', next: 'pets', title: 'CONOCÉ EL PLAN QUE MEJOR SE AJUSTE A TU MASCOTA'},
  { id: 3, name: 'Mascotas', next: 'terms', title: 'INFORMACIÓN DE SU MASCOTA'},
  { id: 4, name: 'Legales', next: 'payment', title: 'TÉRMINOS Y CONDICIONES'},
  { id: 5, name: 'Pago', next: '/', title: 'PAGO'}
];

class SignUpContainer extends React.Component {

  goNext(route) {
    if (this.props.action) this.props.action('signup', this.props.history, route);
    else this.props.history.push(route);
  }

  render() {
    return (
      <div style={{height: '100%'}}>
        <div>
          <div className='signUpTitle'>¡Bienvenido a OSPAN!</div>
          <div className='signUpSubtitle'>Obra social para mascotas | SISTEMA DE PRE-AFILIACIÓN</div>
          <div className='signUpStepperContainer'>
            <Stepper className='stepper' activeStep={this.props.activeStep} alternativeLabel>
              {
                steps.map((step) =>
                  <Step key={`step${step.id}`}>
                    <StepLabel>{step.name}</StepLabel>
                  </Step>
                )
              }
            </Stepper>
          </div>
          <div className='signUpStepTitle'>{steps[this.props.activeStep].title}</div>
          {this.props.children}
          <Grid container className='signupContainerButton'>
            <Grid item xs={12} sm={steps[this.props.activeStep].id === 5 ? 12 : 6} className={steps[this.props.activeStep].id === 5 ? 'centeredButtonContainer' : 'leftButton'} >
              <Button className='homeButton' variant="contained" color="primary" onClick={() => this.props.history.goBack()}>Anterior</Button>
            </Grid>
            {
              steps[this.props.activeStep].id === 5 ?
                null
              :
                <Grid item xs={12} sm={6} className='rightButton' >
                  <Button className='homeButton' variant="contained" color="secondary" onClick={() => this.goNext(steps[this.props.activeStep].next)}>Siguiente</Button>
                </Grid>
            }
          </Grid>
        </div>
      </div>
    );
  }
}

export default SignUpContainer;
