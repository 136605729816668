import * as types from '../constants/actions';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';
import fetch from 'isomorphic-fetch';
import async from 'async';

export function setExportData(values) {
  return {
    type: types.EXPORTCSV_SET_DATA,
    value: values
  };
}
export function updateHeight(value) {
  return {
    type: types.EXPORTCSV_LIST_SET_HEIGHT,
    value
  };
}

export function getExportData() {
  let _uri = config.server;

  return function (dispatch, getSpecie) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getData = function(data, cb) {
      fetch(_uri + '/api/exports',
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          if (response.status === 200) {
            response.json()
            .then(data => {
              cb(null,data);
            })
          } else {
            cb(null,[]);
          }
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      getData
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(setExportData(result));
        }
    });
  }
}
