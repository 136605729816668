import * as types from '../constants/actions';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';
import fetch from 'isomorphic-fetch';
import async from 'async';

export function updateField(value) {return {type: types.BREEDS_EDITOR_UPDATE_FIELD, value};}

export function saveBreed(history) {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const postBreed = function(data,cb) {
      let _data = getState().breedsEditor;
      if(_data.id===0)
        _data.SpecieId = getState().breedsList.selectedSpecie;

      fetch(_uri + `/api/breeds${_data.id===0? '' : `/${_data.id}`}`,
        {
          method: _data.id === 0 ? 'POST' : 'PUT',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(_data)
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(_breed => {
            cb(null,null);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      postBreed
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(MessageActions.show(getState().breedsEditor.id===0?'La raza ha sido dada de alta.':'La raza ha sido modificada.'));
          history.goBack();
        }
    });
  }
}

export function deleteBreed(history) {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const deleteBreed = function(data,cb) {
      let _data = getState().breedsEditor;
      fetch(_uri + `/api/breeds/${_data.id}`,
        {
          method:'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(_breed => {
            cb(null,null);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      deleteBreed
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(MessageActions.show('La raza ha sido eliminada.'));
          history.goBack();
        }
    });
  }
}
