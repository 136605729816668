import * as types from '../constants/actions';
// import fetch from 'isomorphic-fetch';
import async from 'async';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';

export function setTermPostValidation(value) {
  return {
    type: types.TERMS_SET_POST_VALIDATION,
    value
  }
}

export function setTermsPreExistances(value) {
  return {
    type: types.TERMS_SET_PREEXISTANCES,
    value
  }
}

export function setTermsDeficencies(value) {
  return {
    type: types.TERMS_SET_DEFICENCIES,
    value
  }
}

export function setTermsAcceptance(value) {
  return {
    type: types.TERMS_SET_ACCEPTANCE,
    value
  }
}

export function setError(field, message) {
  return {
    type: types.TERMS_SET_ERROR,
    field,
    message
  }
}

export function saveTerms(source, history, nextRoute) {

  return function (dispatch, getState) {
    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const validate = function (data, cb) {
      const _terms = getState().terms;

      if (!_terms.termPostValidation) {
        dispatch (setError('termPostValidation','Debe aceptar las condiciones de afiliación definitiva')); return cb(new Error('Error de validación'));
      }
      if (!_terms.termsPreExistances) {
        dispatch (setError('termsPreExistances','Debe aceptar las condiciones asociadas a las preexistencias')); return cb(new Error('Error de validación'));
      }
      if (!_terms.termsDeficencies) {
        dispatch (setError('termsDeficencies','Debe aceptar las condiciones asociadas a las carencias')); return cb(new Error('Error de validación'));
      }
      if (!_terms.termsAcceptance) {
        dispatch (setError('termsAcceptance','Debe aceptar los términos de uso de la obra social')); return cb(new Error('Error de validación'));
      }
      dispatch(setError(null,''));
      return cb(null, null);
    };

    // const save = function(data, cb) {
    //   const _user = getState().user.user;
    //
    //   fetch(`${_uri}/api/users`,
    //     {
    //       method: 'POST',
    //       headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //       },
    //       body: JSON.stringify(_user)
    //     }
    //   )
    //   .then(function(response) {
    //     if (!response.ok) {
    //       cb(new Error(response.statusText));
    //     } else {
    //       cb(null, null);
    //     }
    //   })
    //   .catch(err => {
    //     cb(err);
    //   });
    // };

    async.waterfall([
      start,
      validate,
      // save
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          if (source === 'signup') history.push(nextRoute);
          else history.goBack();
        }
    });
  }
}
