import * as types from '../constants/actions';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';
import fetch from 'isomorphic-fetch';
import async from 'async';

export function updateField(value) {return {type: types.PETS_EDITOR_UPDATE_FIELD, value};}

export function setNewPhoto(value) {
  return function (dispatch, getState) {
    dispatch(updateField({attr: 'petPhoto', value}));
    dispatch({ type: types.PETS_EDITOR_UPDATE_PHOTO, value:true });
  };
}

export function setSelectedSpecie(value) {return {type: types.PETS_EDITOR_UPDATE_SPECIE, value};}

export function setSelectedBreed(value) {return {type: types.PETS_EDITOR_UPDATE_BREED, value};}

export function savePet(history) {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const postPet = function(data,cb) {
      let _data = {...getState().petsEditor};
      let _photo = _data.pet.petPhoto;
      delete _data.pet.petPhoto;
      fetch(_uri + `/api/pets${_data.pet.id===0? '' : `/${_data.pet.id}`}`,
        {
          method: _data.pet.id === 0 ? 'POST' : 'PUT',
          headers: {
            'Authorization': `Bearer ` + getState().login.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(_data.pet)
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(_pet => {
            if (getState().petsEditor.newPhoto) {
              const formData  = new FormData();
              formData.append('photo', _photo);
              fetch(`${_uri}/api/pets/${_data.pet.id}/photo`, {
                method: 'POST',
                body: formData
              })
              .then(function(response) {
                if (!response.ok) {
                  if (response.status < 500) {
                    response.json()
                    .then(data => {
                      console.error(data.message);
                    })
                  } else {
                    console.error(response.statusText);
                  }
                }
              })
              .catch(error => console.error(JSON.stringify(error)))
              .finally(() => {
                return cb(null, null);
              });
            } else {
              return cb(null, null);
            }
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      postPet
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(MessageActions.show(getState().petsEditor.pet.id===0?'La mascota ha sido dado de alta':'La mascota ha sido modificada'));
          history.goBack();
        }
    });
  }
}

export function deletePet(history) {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const deletePet = function(data,cb) {
      let _data = getState().petsEditor;
      fetch(_uri + `/api/pets/${_data.pet.id}`,
        {
          method:'DELETE',
          headers: {
            'Authorization': `Bearer ` + getState().login.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(_user => {
            cb(null,null);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      deletePet
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(MessageActions.show('La mascota ha sido eliminada.'));
          history.goBack();
        }
    });
  }
}

export function getInitialData() {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getSpecies = function(data, cb) {
      fetch(_uri + '/api/species',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(species => {
            cb(null,{ species });
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    const getBreeds = function(data, cb) {
      fetch(_uri + '/api/breeds',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(breeds => {
            cb(null,{ ...data, breeds });
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    const getHealthPlans = function(data, cb) {
      fetch(_uri + '/api/healthplans',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(healthplans => {
            cb(null,{ ...data, healthplans });
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      getSpecies,
      getBreeds,
      getHealthPlans
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch({type: types.PETS_EDITOR_SET_SPECIES, values: result.species});
          dispatch({type: types.PETS_EDITOR_SET_BREEDS, values: result.breeds});
          dispatch({type: types.PETS_EDITOR_SET_HEALTHPLANS, values: result.healthplans});
        }
    });
  }
}
