import * as types from '../constants/actions';
import fetch from 'isomorphic-fetch';
import async from 'async';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';

export function getInitialData(history) {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getStates = function(data, cb) {
      fetch(_uri + '/api/countries/1/states',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(country => {
            cb(null,{states: country.States});
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    const getCities = function(data, cb) {
      fetch(_uri + '/api/cities',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(cities => {
            cb(null,{ ...data, cities });
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    const getSellers = function(data, cb) {
      fetch(_uri + '/api/sellers',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(sellers => {
            cb(null,{ ...data, sellers });
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      getStates,
      getCities,
      getSellers
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch({type: types.USER_RESET});
          dispatch({type: types.USER_SET_STATES, values: result.states});
          dispatch({type: types.USER_SET_CITIES, values: result.cities});
          dispatch({type: types.USER_SET_SELLERS, values: result.sellers});
        }
    });
  }
}


export function setField(field, newValue) {
  return function (dispatch, getState) {
    let _action = null;
    let _value = newValue;
    switch (field) {
      case 'id': _action = types.USER_SET_ID; break;
      case 'userName': _action = types.USER_SET_NAME; break;
      case 'userLastName': _action = types.USER_SET_LASTNAME; break;
      case 'userEmail': _action = types.USER_SET_EMAIL; break;
      case 'userPhone': _action = types.USER_SET_CELLPHONE; break;
      case 'userPassword': _action = types.USER_SET_PASSWORD; break;
      case 'userRepeatPassword': _action = types.USER_SET_REPEAT_PASSWORD; break;
      case 'userIdNumber':
        _action = types.USER_SET_ID_NUMBER;
        if (/^[0-9]{0,8}$/.test(newValue)) {
          _value = newValue
        } else {
          _value = getState().user.user.userIdNumber;
        }
        break;
      case 'userReferralCode': _action = types.USER_SET_REFERRAL_CODE; break;
      case 'StateId': _action = types.USER_SET_STATE_ID; break;
      case 'CityId': _action = types.USER_SET_CITY_ID; break;
      case 'SellerId': _action = types.USER_SET_SELLER_ID; break;
      case 'CouponId': _action = types.USER_SET_COUPON_ID; break;
      case 'userSubscriptionMethod': _action = types.USER_SET_SUBSCRIPTION_METHOD; break;
      case 'userEmailMP': _action = types.USER_SET_EMAIL_MP; break;
      case 'userCreditCardNumber':
        _action = types.USER_SET_CREDIT_CARD_NUMBER;
        if (/^[0-9]{0,16}$/.test(newValue)) {
          _value = newValue
        } else {
          _value = getState().user.user.userCreditCardNumber;
        }
        break;
      case 'userCreditCardOwnerFullName': _action = types.USER_SET_CREDIT_CARD_OWNER; break;
      case 'userCreditCardOwnerIdNumber':
        _action = types.USER_SET_CREDIT_CARD_OWNER_ID;
        if (/^[0-9]{0,8}$/.test(newValue)) {
          _value = newValue
        } else {
          _value = getState().user.user.userCreditCardOwnerIdNumber;
        }
        break;
      case 'userCreditCardOwnerTaxIdNumber':
        _action = types.USER_SET_CREDIT_CARD_OWNER_TAX_ID;
        if (/^[0-9/]{0,11}$/.test(newValue)) {
          _value = newValue
        } else {
          _value = getState().user.user.userCreditCardOwnerTaxIdNumber;
        }
        break;
      case 'userCreditCardDueDate':
        _action = types.USER_SET_CREDIT_CARD_DUE_DATE;
        if (/^[0-9/]{0,5}$/.test(newValue)) {
          _value = newValue
        } else {
          _value = getState().user.user.userCreditCardDueDate;
        }
        break;
      default:
        _action = null;
    }
    dispatch({ type: _action, value: _value });
  }
}

export function setStateAndGetCities(value) {
  return function (dispatch, getState) {
    dispatch(setField('StateId', value));
    dispatch(setField('CityId', ''));
  }
}

export function setError(field, message) {
  return {
    type: types.USER_SET_ERROR,
    field,
    message
  }
}

export function saveUser(source, history, nextRoute, noGo = 0) {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const validate = function (data, cb) {
      const _user = getState().user.user;
      const _emailPattern = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g;

      if (_user.userName === null || _user.userName === '') {
        dispatch (setError('userName','El nombre no puede ser vacío')); return cb(new Error('Error de validación'));
      }
      if (_user.userLastName === null || _user.userLastName === '') {
        dispatch (setError('userLastName','El apellido no puede ser vacío')); return cb(new Error('Error de validación'));
      }
      if (_user.userEmail === null || _user.userEmail === '') {
        dispatch (setError('userEmail','El email no puede ser vacío')); return cb(new Error('Error de validación'));
      }
      if (!_emailPattern.test(_user.userEmail)) {
        dispatch (setError('userEmail','El email debe ser válido')); return cb(new Error('Error de validación'));
      }
      if (_user.userPhone === null || _user.userPhone === '') {
        dispatch (setError('userPhone','El teléfono no puede ser vacío')); return cb(new Error('Error de validación'));
      }
      if (_user.userPassword === null || _user.userPassword === '' ) {
        dispatch (setError('userPassword','La clave no puede ser vacía')); return cb(new Error('Error de validación'));
      }
      if (_user.userRepeatPassword === null || _user.userRepeatPassword === '' ) {
        dispatch (setError('userRepeatPassword','La repetición de la clave no puede ser vacía')); return cb(new Error('Error de validación'));
      }
      if (_user.userPassword !== _user.userRepeatPassword) {
        dispatch (setError('userPassword','La clave no coincide con su repetición')); return cb(new Error('Error de validación'));
      }
      if (_user.userIdNumber === null || _user.userIdNumber === '') {
        dispatch (setError('userIdNumber','El DNI no puede ser vacío')); return cb(new Error('Error de validación'));
      }
      if (_user.StateId === null || _user.StateId === '') {
        dispatch (setError('StateId','Debe seleccionar la provincia')); return cb(new Error('Error de validación'));
      }
      if (_user.CityId === null || _user.CityId === '') {
        dispatch (setError('CityId','Debe seleccionar la localidad')); return cb(new Error('Error de validación'));
      }
      dispatch(setError(null,''));
      return cb(null, null);
    };

    const save = function(data, cb) {
      const _user = getState().user.user;

      fetch(`${_uri}/api/users`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(_user)
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(data => {
            dispatch(setField('id',data.id));
            cb(null, null);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      validate,
      save
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          if (noGo === 0) {
            if (source === 'signup') history.push(nextRoute);
            else history.goBack();
          }
        }
    });
  }
}
