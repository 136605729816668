import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Container from './container';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoIcon from '@material-ui/icons/PhotoCamera';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Dropzone from 'react-dropzone';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from "@material-ui/pickers";
import * as PetsEditorActions from '../actions/petsEditor';
import config from '../resources/js/config';
import _ from "underscore";
import * as MessageActions from '../actions/message';
import MODULES from '../constants/modules';

class PetEditor extends Component {
  onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length > 0) {
      let _acceptedPhoto = acceptedFiles[0];
      _acceptedPhoto.preview = URL.createObjectURL(_acceptedPhoto);
      this.props.actions.petsEditor.setNewPhoto(_acceptedPhoto);
    } else {
      this.props.actions.message.show('Formato de imagen no reconocido');
    }
  }

  componentDidMount() {
    this.props.actions.petsEditor.getInitialData();
  }

  onSave(){
    if(!this.props.HealthPlanId)
      this.props.actions.message.show("Debe seleccionar el Plan");
    else if(!this.props.petGender)
      this.props.actions.message.show("Debe seleccionar el Genero");
    else if(!this.props.BreedId)
      this.props.actions.message.show("Debe seleccionar la Raza");
    else
      this.props.actions.petsEditor.savePet(this.props.history);
  }

  render() {
    let readOnly = ((this.props.access & MODULES.PETS.level) === 0 || (this.props.roleName === 'USER' && this.props.id > 0));
    let _title= (this.props.id > 0 ? (readOnly ? `Información de ${this.props.petName}` : 'Edición de mascota') : ' Alta de mascota');
    const _sortedSpecies = _.sortBy(this.props.Species, x => x.specieName);
    const _filteredBreeds = _.sortBy(this.props.Breeds.filter(x => x.SpecieId === this.props.SpecieId), x => x.breedName);
    const _sortedHealthPlans = _.sortBy(this.props.HealthPlans, x => x.healthPlanName);
    return (
      <Container {...this.props}>
        <div className='adminTitle'>{_title}</div>
        <Grid container style={{paddingLeft: 16, paddingRight: 16, marginBottom: 48}}>
          <Grid container xs={12}>
            <Grid item xs={12} md={9}>
              <TextField
                fullWidth
                required
                autoFocus
                margin="normal"
                InputLabelProps={{ shrink: true, }}
                label='Nombre'
                value={this.props.petName}
                onChange={(e) => this.props.actions.petsEditor.updateField({attr:'petName', value:e.target.value})}
                disabled={readOnly}
              />
              <FormControl
                fullWidth
                required
                autoFocus
                style={{marginTop: 16}}
                disabled={readOnly}
              >
                <InputLabel shrink>Especie</InputLabel>
                <Select
                  value={this.props.SpecieId}
                  onChange={(event) => this.props.actions.petsEditor.setSelectedSpecie(event.target.value)}
                >
                  <MenuItem key={`petsEditorSpecie_0`} value={0}>{'Ninguna'}</MenuItem>
                  {
                    _sortedSpecies && _sortedSpecies.map((_specie) => {
                      return (<MenuItem key={`petsEditorSpecie_${_specie.id}`} value={_specie.id}>{_specie.specieName}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                required
                autoFocus
                style={{marginTop: 16}}
                disabled={readOnly}
              >
                <InputLabel shrink>Raza</InputLabel>
                <Select
                  value={this.props.BreedId}
                  onChange={(event) => this.props.actions.petsEditor.setSelectedBreed(event.target.value)}
                >
                  <MenuItem key={`petsEditorBreed_0`} value={0}>{'Ninguna'}</MenuItem>
                  {
                    _filteredBreeds.map((_breed) => {
                      return (<MenuItem key={`petsEditorBreed_${_breed.id}`} value={_breed.id}>{_breed.breedName}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                required
                autoFocus
                style={{marginTop: 16}}
                disabled={readOnly}
              >
                <InputLabel shrink>Plan</InputLabel>
                <Select
                  value={this.props.HealthPlanId}
                  onChange={(event) => this.props.actions.petsEditor.updateField({attr:'HealthPlanId', value:event.target.value})}
                >
                  <MenuItem key={`petsEditorHealthPlan_0`} value={0}>{'Ninguno'}</MenuItem>
                  {
                    _sortedHealthPlans && _sortedHealthPlans.map((_plan) => {
                      return (<MenuItem key={`petsEditorHealthPlan_${_plan.id}`} value={_plan.id}>{_plan.healthPlanName}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                required
                autoFocus
                style={{marginTop: 16}}
                disabled={readOnly}
              >
                <InputLabel shrink>Género</InputLabel>
                <Select
                  value={this.props.petGender}
                  onChange={(event) => this.props.actions.petsEditor.updateField({attr:'petGender', value:event.target.value})}
                >
                  <MenuItem key={`petsEditorGender_X`} value={'X'}>{'Ninguno'}</MenuItem>
                  <MenuItem key={`petsEditorGender_H`} value={'H'}>{'Hembra'}</MenuItem>
                  <MenuItem key={`petsEditorGender_M`} value={'M'}>{'Macho'}</MenuItem>
                </Select>
              </FormControl>
              {
                this.props.roleName === 'USER' ? null :
                <TextField
                  fullWidth
                  autoFocus
                  margin="normal"
                  InputLabelProps={{ shrink: true, }}
                  label='Código'
                  value={this.props.petInternalID}
                  onChange={(e) => this.props.actions.petsEditor.updateField({attr:'petInternalID', value:e.target.value})}
                  disabled={readOnly}
                />
              }
              <KeyboardDatePicker
                autoOk
                required
                style={{width:'100%', marginTop: 16, marginBottom: 8}}
                label="Fecha de nacimiento estimada"
                format="DD/MM/YYYY"
                maxDate={Date.now()}
                cancelLabel="CANCELAR"
                okLabel="ACEPTAR"
                value={this.props.petBirthDate}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => this.props.actions.petsEditor.updateField({attr:'petBirthDate', value:date})}
                disabled={readOnly}
              />
              {
                readOnly || this.props.id === 0 ? null :
                <KeyboardDatePicker
                  autoOk
                  required
                  style={{width:'100%', marginTop: 16, marginBottom: 8}}
                  label="Fecha de defunción"
                  format="DD/MM/YYYY"
                  maxDate={Date.now()}
                  cancelLabel="CANCELAR"
                  okLabel="ACEPTAR"
                  value={this.props.petDeathDate}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={date => this.props.actions.petsEditor.updateField({attr:'petDeathDate', value:date})}
                  disabled={readOnly}
                />
              }
              {
                this.props.roleName === 'USER' ? null :
                <KeyboardDatePicker
                  autoOk
                  required
                  style={{width:'100%', marginTop: 16, marginBottom: 8}}
                  label="Fecha de alta"
                  format="DD/MM/YYYY"
                  maxDate={Date.now()}
                  cancelLabel="CANCELAR"
                  okLabel="ACEPTAR"
                  value={this.props.petSignUpDate}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={date => this.props.actions.petsEditor.updateField({attr:'petSignUpDate', value:date})}
                  disabled={readOnly}
                />
              }
              {
                this.props.roleName === 'USER' || this.props.id === 0 ? null :
                <TextField
                  fullWidth
                  autoFocus
                  margin="normal"
                  InputLabelProps={{ shrink: true, }}
                  label='Comentario'
                  value={this.props.petComment}
                  onChange={(e) => this.props.actions.petsEditor.updateField({attr:'petComment', value:e.target.value})}
                  disabled={readOnly}
                />
              }
            </Grid>
            <Grid item xs={12} md={3} style={{height: 264}}>
              <FormControl
                fullWidth
                required
                style={{paddingTop: 16, justifyContent: 'center', display: 'flex', alignItems: 'center'}}
              >
                <Dropzone onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles)} multiple={false} accept={[".jpg",".jpeg",".png"]}>
                  {({getRootProps, getInputProps, isDragActive}) => {
                    return (
                      <div
                        {...getRootProps()}
                        style={{position: 'relative', cursor:'pointer', borderWidth: 1, borderColor: 'black', borderStyle: 'solid', color: '#00000066', width: 256, height: 256}}
                      >
                        <input {...getInputProps()} />
                        <img id="preview" style={{width: 256, height: 256, position: 'absolute', top: 0, left: 0}} src={this.props.petPhoto? this.props.petPhoto.preview : (this.props.id ? `${config.server}/img/${this.props.id}.jpg?dummy=${Math.floor(Math.random() * 100000)}`: "")} alt="Foto de la mascota" />
                        <Fab key='petDelete' size="small" color="secondary" aria-label="Delete" style={{position: 'relative', top: 204, left: 204}}>
                          <PhotoIcon />
                        </Fab>
                      </div>
                    )
                  }}
                </Dropzone>
                {
                  this.props.id > 0 ?
                    <Button className='homeButton' variant="contained" color='secondary' style={{width: 256, marginTop: 20}} onClick={() => this.props.history.push(`credential/${this.props.id}`)}>Credencial virtual</Button>
                  : null
                }

              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Fab key='petClose' size="small" color="secondary" aria-label="Discard" style={{position: 'fixed', top: '80px', right: '20px'}} onClick={() => this.props.history.goBack()}>
          <CloseIcon />
        </Fab>
        {
          !readOnly || this.props.id === 0 || this.props.newPhoto ?
            <Fab key='petSave' size="small" color="secondary" aria-label="Save" style={{position: 'fixed', bottom: '60px', right: '20px'}} onClick={() => this.onSave()}>
              <SaveIcon />
            </Fab>
          : null
        }
        {
          this.props.id > 0 && !readOnly ?
            <Fab key='petDelete' size="small" color="secondary" aria-label="Delete" style={{position: 'fixed', bottom: '110px', right: '20px'}} onClick={() => this.props.actions.petsEditor.deletePet(this.props.history)}>
              <DeleteIcon />
            </Fab>
          : null
        }
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    id: state.petsEditor.pet.id,
    petName: state.petsEditor.pet.petName,
    petGender: state.petsEditor.pet.petGender,
    petBirthDate: state.petsEditor.pet.petBirthDate,
    petDeathDate: state.petsEditor.pet.petDeathDate,
    petSignUpDate: state.petsEditor.pet.petSignUpDate,
    petInternalID: state.petsEditor.pet.petInternalID,
    petComment: state.petsEditor.pet.petComment,
    petPhoto: state.petsEditor.pet.petPhoto,
    SpecieId: state.petsEditor.pet.Breed.Specie.id,
    BreedId: state.petsEditor.pet.Breed.id,
    HealthPlanId: state.petsEditor.pet.HealthPlanId,
    Species: state.petsEditor.Species,
    Breeds: state.petsEditor.Breeds,
    HealthPlans: state.petsEditor.HealthPlans,
    newPhoto: state.petsEditor.newPhoto,
    roleName: state.login.roleName,
    modules: state.login.modules,
    access: state.login.access
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      message: bindActionCreators(MessageActions, dispatch),
      petsEditor: bindActionCreators(PetsEditorActions, dispatch),
    }
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(PetEditor);
