import * as types from '../constants/actions';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';
import fetch from 'isomorphic-fetch';
import async from 'async';

export function updateField(value) {return {type: types.SPECIES_EDITOR_UPDATE_FIELD, value};}

export function saveSpecie(history) {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const postSpecie = function(data,cb) {
      let _data = getState().speciesEditor;
      fetch(_uri + `/api/species${_data.id===0? '' : `/${_data.id}`}`,
        {
          method: _data.id === 0 ? 'POST' : 'PUT',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(_data)
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(_specie => {
            cb(null,null);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      postSpecie
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(MessageActions.show(getState().speciesEditor.id===0?'La especie ha sido dado de alta.':'La especie ha sido modificado.'));
          history.goBack();
        }
    });
  }
}

export function deleteSpecie(history) {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const deleteSpecie = function(data,cb) {
      let _data = getState().speciesEditor;
      fetch(_uri + `/api/species/${_data.id}`,
        {
          method:'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(_specie => {
            cb(null,null);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      deleteSpecie
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(MessageActions.show('La especie ha sido eliminado.'));
          history.goBack();
        }
    });
  }
}
