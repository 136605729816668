import * as types from '../constants/actions';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';
import fetch from 'isomorphic-fetch';
import async from 'async';
import download from 'downloadjs';

export function downloadSubscriptionForm() {
  const _uri = config.server;

  return (dispatch, getState) => {
    const userId = getState().usersEditor.user.id;

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getForm = function(data,cb) {

      fetch(`${_uri}/api/users/${userId}/subscriptionForm`,
        {
          method: 'GET',
          headers: {'Authorization': `Bearer ${getState().login.token}`}
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.blob()
          .then(_form => {
            download(_form, `${getState().usersEditor.user.userLastName}_${getState().usersEditor.user.userName}.pdf`);
            cb(null,null);
          })
          .catch(err => {
            cb(err);
          });
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      getForm
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        }
    });
  }
}

export function updateField(value) {return {type: types.USERS_EDITOR_UPDATE_FIELD, value};}

export function changePermissions(module, permission) {
  return function (dispatch, getState) {
    let _modules = getState().usersEditor.user.userModules;
    let _permissions = getState().usersEditor.user.userPermissions;
    if (permission === 'locked') {
      _modules = _modules - (_modules & module);
      _permissions = _permissions - (_permissions & module);
    } else if (permission === 'readonly') {
      _modules = _modules + (_modules & module ? 0 : module);
      _permissions = _permissions - (_permissions & module);
    } else {
      _modules = _modules + (_modules & module ? 0 : module);
      _permissions = _permissions + (_permissions & module ? 0 : module);
    }
    dispatch(updateField({attr:'userModules', value: _modules}));
    dispatch(updateField({attr:'userPermissions', value: _permissions}));
  };
};

export function showUserChangePwdDialog(value) {return {type: types.USERS_EDITOR_CHANGE_PASSWORD_DIALOG, value};}

export function showPaymentDialog(value) {return {type: types.USERS_EDITOR_CHANGE_PAYMENT_DIALOG, value};}

export function clearUserPwd() {return {type: types.USERS_EDITOR_CLEAR_PASSWORD_DIALOG};}

export function setSelectedState(value) {return {type: types.USERS_EDITOR_UPDATE_STATE, value};}

export function setSelectedCity(value) {return {type: types.USERS_EDITOR_UPDATE_CITY, value};}

export function changeUserPassword(history) {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const changePassword = function(data,cb) {
      let _data = {
        oldPass:getState().usersEditor.user.userOldPass,
        newPass:getState().usersEditor.user.userNewPass,
      };

      fetch(_uri + `/api/users/${getState().usersEditor.user.id}/changepassword`,
        {
          method:'PUT',
          headers: {
            'Authorization': `Bearer `+ localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(_data)
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(_user => {
            cb(null,null);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      changePassword
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(clearUserPwd());
          dispatch(showUserChangePwdDialog(false));
          dispatch(MessageActions.show('La clave ha sido actualizada'));
        }
    });
  }
}

export function saveUser(history) {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const postUser = function(data,cb) {
      let _data = getState().usersEditor;
      fetch(_uri + `/api/users${_data.user.id===0? '' : `/${_data.user.id}`}`,
        {
          method: _data.user.id === 0 ? 'POST' : 'PUT',
          headers: {
            'Authorization': `Bearer ${getState().login.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(_data.user)
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(_user => {
            cb(null,null);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      postUser
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(MessageActions.show(getState().usersEditor.user.id===0?'El usuario ha sido dado de alta.':'El usuario ha sido modificado.'));
          history.goBack();
        }
    });
  }
}

export function deleteUser(history) {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const deleteUser = function(data,cb) {
      let _data = getState().usersEditor;
      fetch(_uri + `/api/users/${_data.user.id}`,
        {
          method:'DELETE',
          headers: {
            'Authorization': `Bearer ${getState().login.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(_user => {
            cb(null,null);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      deleteUser
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(MessageActions.show('El usuario ha sido eliminado.'));
          history.goBack();
        }
    });
  }
}

export function getInitialData(id = 0) {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getStates = function(data, cb) {
      fetch(_uri + '/api/states',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(states => {
            cb(null,{ states });
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    const getCities = function(data, cb) {
      fetch(_uri + '/api/cities',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(cities => {
            cb(null,{ ...data, cities });
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    const getSellers = function(data, cb) {
      fetch(_uri + '/api/sellers',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(sellers => {
            cb(null,{ ...data, sellers });
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    const getUser = (data, cb) => {
      if (id === 0) cb(null, data);
      else {
        fetch(`${_uri}/api/users/${id}`,
          {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          }
        )
        .then(function(response) {
          if (!response.ok) {
            if (response.status < 500) {
              response.json()
              .then(data => {
                cb(new Error(data.message));
              })
            } else {
              cb(new Error(response.statusText));
            }
          } else {
            response.json()
            .then(user => {
              cb(null,{ ...data, user });
            })
          }
        })
        .catch(err => {
          cb(err);
        });
      }
    }

    async.waterfall([
      start,
      getStates,
      getCities,
      getSellers,
      getUser
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch({type: types.USERS_EDITOR_SET_STATES, values: result.states});
          dispatch({type: types.USERS_EDITOR_SET_CITIES, values: result.cities});
          dispatch({type: types.USERS_EDITOR_SET_SELLERS, values: result.sellers});
          if (id !== 0) dispatch({type: types.USERS_LIST_SET_SELECTED_USER, value: result.user});
        }
    });
  }
}

export function doThinkSoftRegistration(id) {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const postRegistration = function(data,cb) {
      fetch(_uri + `/api/users/${id}/tsregistration`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${getState().login.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => cb(new Error(data.message)));
          } else {
            return cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(result => cb(null, result));
        }
      })
      .catch(err => cb(err));
    };

    async.waterfall([
      start,
      postRegistration
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(updateField({ attr: 'thinkSoftRegistrationStatus', value: 'S' }));
          dispatch(updateField({ attr: 'thinkSoftRegistrationComment', value: 'Registrado en ThinkSoft' }));
          dispatch(MessageActions.show('El usuario ha sido registrado correctamente'));
        }
    });
  }
}
