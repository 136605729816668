import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import VirtualizedTable from './virtualizedTable';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Container from './container';
import * as SellersListActions from '../actions/sellersList';
import * as ContainerActions from '../actions/container';

class AdminSellersPage extends Component {

  updateDimensions() {
    var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
    this.props.actions.SellersList.updateHeight(height - 64);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.updateDimensions();
    this.props.actions.SellersList.getInitialData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    return (
      <Container {...this.props}>
        <div style={{height: this.props.height}}>
          <div className='adminTitle'>Listado de asesores</div>
          <VirtualizedTable
            rowCount={this.props.sellers.length}
            rowGetter={({ index }) => this.props.sellers[index]}
            onRowClick={(event) => this.props.actions.SellersList.selectSeller(event.rowData,this.props.history)}
            columns={[
              { width: 120, flexGrow: 1.0, label: 'Nombre', dataKey: 'sellerName' },
            ]}
          />
        </div>
        <Fab color='secondary' size='medium' style={{position: 'fixed', bottom: 60, right: 20}}>
          <AddIcon onClick={() => this.props.actions.SellersList.selectSeller(null,this.props.history)}/>
        </Fab>

      </Container>

    );
  }
}

function mapStateToProps(state) {
  return {
    height: state.sellersList.height,
    sellers: state.sellersList.sellers,
    selectedSeller: state.sellersList.selectedSeller
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      SellersList: bindActionCreators(SellersListActions, dispatch),
      Container: bindActionCreators(ContainerActions, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSellersPage);
