import * as types from '../constants/actions';
import fetch from 'isomorphic-fetch';
import async from 'async';
import moment from 'moment';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';
import * as UserActions from '../actions/user';

export function setError(field, message) {
  return {
    type: types.USER_SET_ERROR,
    field,
    message
  }
}

export function setCouponText(value) {
  return {
    type: types.PAYMENT_SET_COUPON_TEXT,
    value
  }
}

export function checkPayment() {
  return (dispatch, getState) => {
    if(getState().container.paymentPendingDialog !==null)
      return dispatch({type: null});

    const _uri = config.server;
    const _role = getState().login.roleName;

    if (_role === 'USER') {
      const start = function(cbStart) {
        dispatch(OverlayActions.show());
        return cbStart(null,null);
      };

      const end = function() {
        dispatch(OverlayActions.hide());
      };

      const validate = function(data, cbValidate) {
        fetch(`${_uri}/api/payment/validate/${getState().login.userId}`,
          {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then(function(response) {
          if (!response.ok) {
            if (response.status < 500) {
              response.json()
              .then(data => {
                cbValidate(new Error(data.message));
              })
            } else {
              cbValidate(new Error(response.statusText));
            }
          } else {
            if (response.status === 204) {
              dispatch({type: types.CONTAINER_PAYMENT_PENDING, value: true});
              dispatch({type: types.CONTAINER_TOGGLE_DIALOG, value: true});
            } else {
              dispatch({type: types.CONTAINER_PAYMENT_PENDING, value: false});
              dispatch({type: types.CONTAINER_TOGGLE_DIALOG, value: false});
            }
            return cbValidate(null, null);
          }
        })
        .catch(err => {
          return cbValidate(err);
        });
      }

      async.waterfall([
        start,
        validate
      ], function (error,result) {
          end();
          if (error) {
            console.log(error);
            dispatch(MessageActions.show(error.message));
          } else {
            dispatch({ type: null });
          }
      });
    } else {
      dispatch({type: types.CONTAINER_PAYMENT_PENDING, value: false});
      dispatch({type: types.CONTAINER_TOGGLE_DIALOG, value: false});
    }
  }
}

export function setTotalValue(value) {
  return {
    type: types.PAYMENT_SET_TOTAL_VALUE,
    value
  }
}

export function setOriginalValue(value) {
  return {
    type: types.PAYMENT_SET_ORIGINAL_VALUE,
    value
  }
}

export function setCoupon(value) {
  return {
    type: types.PAYMENT_SET_COUPON,
    value
  }
}

export function applyCoupon(userId = 0) {
  return (dispatch, getState) => {
    const _coupon = getState().payment.Coupon;
    if(_coupon) {
      dispatch(UserActions.setField('CouponId',_coupon.id));
      dispatch(setPriceToPay(userId, _coupon.id));
    } else {
      dispatch(setPriceToPay(userId, 0));
    }
  }
}

export function setPriceToPay(userId, couponId) {
  return (dispatch) => {
    const _uri = config.server;
    fetch(`${_uri}/api/payment/pricetopay/${userId}/${couponId}`, { method: 'GET' })
    .then(response => {
      if (!response.ok) {
        if (response.status < 500) {
          response.json()
          .then(data => {
            dispatch(MessageActions.show(data.message));
          })
        } else {
          dispatch(MessageActions.show(response.statusText));
        }
      } else {
        response.json()
        .then(data => {
          dispatch(setOriginalValue(data.price < 0 ? 0: data.price));
          dispatch({type: types.PET_SET_TOTAL_PRICE, value: (data.price < 0 ? 0: data.price)});
        })
      }
    })
    .catch(err => {
      dispatch(MessageActions.show(err.message));
    });
  }
}

export function validateCoupon(value, userId = 0) {
  let _uri = config.server;

  return (dispatch, getState) => {
    const start = function(cbStart) {
      dispatch(OverlayActions.show());
      return cbStart(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const validate = function(data, cbValidate) {
      fetch(`${_uri}/api/coupons/validate/${userId}/${value}`,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cbValidate(new Error(data.message));
            })
          } else {
            cbValidate(new Error(response.statusText));
          }
        } else {
          if (response.status === 204) {
            dispatch(setCoupon(null));
            dispatch(applyCoupon(userId));
            return cbValidate(new Error('No se encontró el cupón ingresado'), null);
          } else {
            response.json()
            .then(data => {
              dispatch(setCoupon(data));
              dispatch(applyCoupon(userId));
              return cbValidate(null, null);
            })
          }
        }
      })
      .catch(err => {
        return cbValidate(err);
      });
    }

    async.waterfall([
      start,
      validate
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch({ type: null });
        }
    });
  }
}

export function createPreference() {
  let _uri = config.server;

  return (dispatch, getState) => {
    const start = function(cbStart) {
      dispatch(OverlayActions.show());
      return cbStart(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const updateUser = function(data, cbUpdate) {
      dispatch(UserActions.saveUser(null,null,null,1));
      cbUpdate(null, null);
    };

    const validate = function (data, cb) {
      const _user = getState().user.user;

      if (_user.userSubscriptionMethod === 'M' && (_user.userEmailMP === null || _user.userEmailMP === '')) {
        dispatch (setError('userEmailMP','El email asociado a mercado pago es obligatorio')); return cb(new Error('Error de validación'));
      }
      if (['C','D','S'].includes(_user.userSubscriptionMethod)  && (_user.userCreditCardNumber === null || _user.userCreditCardNumber === '')) {
        dispatch (setError('userCreditCardNumber','El número de tarjeta de crédito es obligatorio')); return cb(new Error('Error de validación'));
      }
      if (['C','D','S'].includes(_user.userSubscriptionMethod) && (_user.userCreditCardOwnerFullName === null || _user.userCreditCardOwnerFullName === '')) {
        dispatch (setError('userCreditCardOwnerFullName','El nombre del titular es obligatorio')); return cb(new Error('Error de validación'));
      }
      if (['C','D','S'].includes(_user.userSubscriptionMethod) && (_user.userCreditCardDueDate === null || _user.userCreditCardDueDate === '')) {
        dispatch (setError('userCreditCardDueDate','La fecha de vencimiento de la tarjeta es obligatoria')); return cb(new Error('Error de validación'));
      }
      if (['C','D','S'].includes(_user.userSubscriptionMethod) && (_user.userCreditCardOwnerIdNumber === null || _user.userCreditCardOwnerIdNumber === '')) {
        dispatch (setError('userCreditCardOwnerIdNumber','El DNI del titular es obligatorio')); return cb(new Error('Error de validación'));
      }
      if (['C','D','S'].includes(_user.userSubscriptionMethod) && !(/^[0-9]{16}$/.test(_user.userCreditCardNumber))) {
        dispatch (setError('userCreditCardNumber','El número de tarjeta es incorrecto (deben ser 16 dígitos numéricos)')); return cb(new Error('Error de validación'));
      }
      if (['C','D','S'].includes(_user.userSubscriptionMethod) && !(/^(0[1-9]|1[0-2])\/([0-9]{2})$/.test(_user.userCreditCardDueDate))) {
        dispatch (setError('userCreditCardDueDate','El formato de la fecha de vencimiento es incorrecto (debe ser MM/AA)')); return cb(new Error('Error de validación'));
      }
      if (['C','D','S'].includes(_user.userSubscriptionMethod) && !(/^[0-9]{8}$/.test(_user.userCreditCardOwnerIdNumber))) {
        dispatch (setError('userCreditCardOwnerIdNumber','El formato del DNI incorrecto (deben ser 8 dígitos numéricos)')); return cb(new Error('Error de validación'));
      }
      if (['C','D','S'].includes(_user.userSubscriptionMethod) && !(/^[0-9/]{11}$/.test(_user.userCreditCardOwnerTaxIdNumber))) {
        dispatch (setError('userCreditCardOwnerTaxIdNumber','El formato del CUIT es incorrecto (deben ser 11 dígitos numéricos)')); return cb(new Error('Error de validación'));
      }

      dispatch(setError(null,''));
      return cb(null, null);
    };

    const save = function(data, cbSave) {
      fetch(`${_uri}/api/payment/preference`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            UserId: getState().user.user.id,
            price: moment().format('D') >= 15 ? getState().pet.totalPrice / 2 : getState().pet.totalPrice
          })
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cbSave(new Error(data.message));
            })
          } else {
            cbSave(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(data => {
            window.location.replace(data.result);
            return cbSave(null, null);
          })
        }
      })
      .catch(err => {
        return cbSave(err);
      });
    }

    async.waterfall([
      start,
      validate,
      updateUser,
      save
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch({ type: null });
        }
    });
  }
}

export function createPreferenceFromScratch(userId, couponId = null) {
  let _uri = config.server;
  return (dispatch, getState) => {
    const start = function(cbStart) {
      dispatch(OverlayActions.show());
      return cbStart(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const save = function(data, cbSave) {
      fetch(`${_uri}/api/payment/preference/${userId}`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ couponId })
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cbSave(new Error(data.message));
            })
          } else {
            cbSave(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(data => {
            window.open(data.result, '_blank', 'noopener,noreferrer');
            return cbSave(null, null);
          })
        }
      })
      .catch(err => {
        return cbSave(err);
      });
    }

    async.waterfall([
      start,
      save
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch({ type: null });
        }
    });
  }
}
