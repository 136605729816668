import React, { Component } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from './components/home';
import Login from './components/login';
import SignUp from './components/signup';
import Plans from './components/plans';
import Pets from './components/pets';
import Terms from './components/terms';
import Credential from './components/credential';
import Payment from './components/payment';
import MainContainer from './components/mainContainer';
import AdminPets from './components/admin-pets';
import EditPet from './components/admin-pets-editor';
import AdminStates from './components/admin-states';
import EditState from './components/admin-states-editor';
import AdminCoupons from './components/admin-coupons';
import EditCoupon from './components/admin-coupons-editor';
import AdminHealthPlans from './components/admin-healthPlans';
import EditHealthPlan from './components/admin-healthPlans-editor';
import AdminSpecies from './components/admin-species';
import EditSpecie from './components/admin-species-editor';
import AdminSellers from './components/admin-sellers';
import EditSeller from './components/admin-sellers-editor';
import AdminUsers from './components/admin-users';
import EditUser from './components/admin-users-editor';
import AdminCities from './components/admin-cities';
import EditCity from './components/admin-cities-editor';
import AdminBreeds from './components/admin-breeds';
import EditBreed from './components/admin-breeds-editor';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ExportCSV from './components/admin-exportCSV';
import * as LoginActions from './actions/login';
import moment from 'moment';
import 'moment/locale/es';

import './App.css';

moment.locale('es');

const theme = createTheme(
  {
    palette: {
      primary: {
        light: '#515570',
        main: '#272c45',
        dark: '#00001e',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ffd3aa',
        main: '#d7a27b',
        dark: '#a4734e',
        contrastText: '#fff',
      },
    },
    status: {
      danger: 'orange',
    },
    typography: {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial", sans-serif`,
      useNextVariants: true,
    },
    progress: {
      margin: 16,
    },
    close: {
      padding: 4,
    },
  }
);

class App extends Component {
  constructor() {
    super();

    this.loggedIn = function (nextState, replaceState) {
      if((localStorage !== null && localStorage.getItem('data') !== null))
      {
        let _data = JSON.parse(localStorage.getItem('data'));
        this.props.actions.login.loginSetData(_data);
        return true;
      }
      else
        return false;
    };

    this.routes =
      <Switch>
        <Route exact path="/" render={(props) => <Home {...props} />} />
        <Route path="/login" render={(props) => <Login {...props} />} />
        <Route path="/signup" render={(props) => <SignUp {...props} />} />
        <Route path="/plans" render={(props) => <Plans {...props} />} />
        <Route path="/pets" render={(props) => <Pets {...props} />} />
        <Route path="/terms" render={(props) => <Terms {...props} />} />
        <Route path="/payment" render={(props) => <Payment {...props} />} />
        <Route path="/admin-pets-edit" render={(props) => ( this.loggedIn() ? (<EditPet {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-pets/:id" render={(props) => ( this.loggedIn() ? (<AdminPets {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-pets" render={(props) => ( this.loggedIn() ? (<AdminPets {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-states-edit" render={(props) => ( this.loggedIn() ? (<EditState {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-states" render={(props) => ( this.loggedIn() ? (<AdminStates {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-coupons-edit" render={(props) => ( this.loggedIn() ? (<EditCoupon {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-coupons" render={(props) => ( this.loggedIn() ? (<AdminCoupons {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-healthPlans-edit" render={(props) => ( this.loggedIn() ? (<EditHealthPlan {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-healthPlans" render={(props) => ( this.loggedIn() ? (<AdminHealthPlans {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-species-edit" render={(props) => ( this.loggedIn() ? (<EditSpecie {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-species" render={(props) => ( this.loggedIn() ? (<AdminSpecies {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-sellers-edit" render={(props) => ( this.loggedIn() ? (<EditSeller {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-sellers" render={(props) => ( this.loggedIn() ? (<AdminSellers {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-users-edit/:id" render={(props) => ( this.loggedIn() ? (<EditUser {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-users-edit" render={(props) => ( this.loggedIn() ? (<EditUser {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-users" render={(props) => ( this.loggedIn() ? (<AdminUsers {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-cities-edit" render={(props) => ( this.loggedIn() ? (<EditCity {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-cities" render={(props) => ( this.loggedIn() ? (<AdminCities {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-breeds-edit" render={(props) => ( this.loggedIn() ? (<EditBreed {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-breeds" render={(props) => ( this.loggedIn() ? (<AdminBreeds {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/credential/:id" render={(props) => ( this.loggedIn() ? (<Credential {...props} />) : (<Redirect to="/login"/>))} />
        <Route path="/admin-exportcsv" render={(props) => ( this.loggedIn() ? (<ExportCSV {...props} />) : (<Redirect to="/login"/>))} />
      </Switch>;
  }

  // <Route path="/admin-pets" render={(props) => <AdminPets {...props} />} />
  // <Route path="/admin-pets" render={(props) => ( this.loggedIn() ? (<AdminPets {...props} />) : (<Redirect to="/login"/>))} />

  render() {
    return (
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale='es'>
            <MainContainer>
              <BrowserRouter>
                {
                  this.routes
                }
              </BrowserRouter>
            </MainContainer>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.login.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login: bindActionCreators(LoginActions, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
