import { HEALTHPLANS_EDITOR_UPDATE_FIELD, HEALTHPLANS_LIST_SET_SELECTED_HEALTHPLAN} from '../constants/actions';

const _initHealthPlan={id: 0, healthPlanName: "", healthPlanPrice:0, healthPlanStatus: "A"}

export default function statesEditor(state = _initHealthPlan, action) {
  let _state= {...state};
  switch (action.type) {
    case HEALTHPLANS_EDITOR_UPDATE_FIELD:
      _state[action.value.attr] = action.value.value;
      return _state;
    case HEALTHPLANS_LIST_SET_SELECTED_HEALTHPLAN:
      return action.value?action.value:_initHealthPlan;
    default:
      return state;
  }
}
