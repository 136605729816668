import {
  PET_RESET,
  PET_SET_ID,
  PET_SET_NAME,
  PET_SET_BIRTHDATE,
  PET_SET_GENDER_ID,
  PET_SET_SPECIE_ID,
  PET_SET_BREED_ID,
  PET_SET_HEALTHPLAN_ID,
  PET_SET_SPECIES,
  PET_SET_HEALTHPLANS,
  PET_SET_BREEDS,
  PET_SET_ERROR,
  PET_SET_PETS,
  PET_SET_USER_ID,
  PET_ADD,
  PET_REMOVE,
  PET_SET_TOTAL_PRICE,
  PET_SET_PHOTO
} from '../constants/actions';

const INITAL_STATE = {
  pet: {
    id: 0,
    petName: '',
    petAge: '',
    petGender: '',
    SpecieId: '',
    BreedId: '',
    HealthPlanId: '',
    petStatus: 'A',
    UserId: 1,
    petPhoto: {}
  },
  error: {
    errorField: null,
    errorMessage: ''
  },
  totalPrice: 0,
  pets: [],
  species: [],
  breeds: [],
  healthPlans: []
};

export default function overlay(state = {...INITAL_STATE}, action) {
  let _pet = {...state.pet};
  let _error = {...state.error};
  switch (action.type) {
  case PET_RESET:
    return {...INITAL_STATE};
  case PET_SET_ID:
    _pet.id = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_NAME:
    _pet.petName = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_BIRTHDATE:
    _pet.petBirthDate = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_GENDER_ID:
    _pet.petGender = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_PHOTO:
    _pet.petPhoto = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_TOTAL_PRICE:
    return {...state, totalPrice: action.value};
  case PET_SET_SPECIE_ID:
    _pet.SpecieId = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_BREED_ID:
    _pet.BreedId = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_HEALTHPLAN_ID:
    _pet.HealthPlanId = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_SPECIES:
    return {...state, species: [...action.values]};
  case PET_SET_HEALTHPLANS:
    return {...state, healthPlans: [...action.values]};
  case PET_SET_BREEDS:
    return {...state, breeds: [...action.values]};
  case PET_SET_PETS:
    return {...state, pets: [...action.values]};
  case PET_SET_USER_ID:
    _pet.UserId = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_ERROR:
    _error.errorField = action.field;
    _error.errorMessage = action.message;
    return {...state, error: {..._error}};
  case PET_ADD:
    const _plusPetArray = [...state.pets];
    _plusPetArray.push(action.value);
    return {...state, pets: [..._plusPetArray]};
  case PET_REMOVE:
    const _removePetArray = [...state.pets];
    if (action.value > -1) _removePetArray.splice(action.value, 1);
    return {...state, pets: [..._removePetArray]};
  default:
    return state;
  }
}
