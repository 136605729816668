import moment from 'moment';
import {
  USERS_EDITOR_UPDATE_FIELD,
  USERS_LIST_SET_SELECTED_USER,
  USERS_EDITOR_SET_STATES,
  USERS_EDITOR_SET_CITIES,
  USERS_EDITOR_SET_SELLERS,
  USERS_EDITOR_UPDATE_STATE,
  USERS_EDITOR_UPDATE_CITY,
  USERS_EDITOR_RESET,
  USERS_EDITOR_CHANGE_PASSWORD_DIALOG,
  USERS_EDITOR_CLEAR_PASSWORD_DIALOG,
  USERS_EDITOR_CHANGE_PAYMENT_DIALOG
} from '../constants/actions';

const _newUser = {
  id: 0,
  userName: '',
  userLastName: '',
  userStatus: 'A',
  RoleId: 3,
  userModules: 5,
  userPermissions: 4,
  StateId: 0,
  CityId: 0,
  userEmail: '',
  userIdNumber: '',
  userPhone: '',
  Pets: [],
  userSubscriptionMethod: 'N',
  userCreditCardNumber: '',
  userCreditCardOwnerFullName: '',
  userCreditCardOwnerIdNumber: '',
  userOldPass:'',
  userNewPass:'',
  userNewPassVerified:'',
  userCreditCardOwnerTaxIdNumber: '',
  userCreditCardDueDate: moment().format('MM/YY')
}

const INITIAL_STATE = {
  user: {..._newUser},
  userChangePwdDialog:false,
  userPaymentDialog:false,
  States: [],
  Cities: [],
  Sellers: []
};

export default function usersEditor(state = INITIAL_STATE, action) {
  let _state= {...state};
  switch (action.type) {
    case USERS_EDITOR_UPDATE_FIELD:
      if (action.value.attr === 'RoleId' && action.value.value === 3) {
        _state.user['userModules'] = 5;
        _state.user['userPermissions'] = 4;
      }
      _state.user[action.value.attr] = action.value.value;
      return _state;
    case USERS_EDITOR_SET_STATES:
      return {..._state, States: [...action.values]};
    case USERS_EDITOR_SET_CITIES:
      return {..._state, Cities: [...action.values]};
    case USERS_EDITOR_SET_SELLERS:
      return {..._state, Sellers: [...action.values]};
    case USERS_EDITOR_UPDATE_STATE:
      _state.user.CityId = 0;
      _state.user.StateId = action.value;
      return _state;
    case USERS_EDITOR_UPDATE_CITY:
      _state.user.CityId = action.value;
      return _state;
    case USERS_EDITOR_RESET:
      return {...INITIAL_STATE};
    case USERS_LIST_SET_SELECTED_USER:
      _state.user = action.value ? action.value : {..._newUser};
      return _state;
    case USERS_EDITOR_CHANGE_PASSWORD_DIALOG:
      _state.userChangePwdDialog = action.value;
      return _state;
    case USERS_EDITOR_CHANGE_PAYMENT_DIALOG:
      _state.userPaymentDialog = action.value;
      return _state;
    case USERS_EDITOR_CLEAR_PASSWORD_DIALOG:
      _state.user.userOldPass='';
      _state.user.userNewPass='';
      _state.user.userNewPassVerified='';
      return _state;
    default:
      return state;
  }
}
