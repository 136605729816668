import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Container from './container';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import * as HealthPlansEditorActions from '../actions/healthPlansEditor';
import MODULES from '../constants/modules';

class HealthPlanEditor extends Component {
  render() {
    const readOnly = (((this.props.access & MODULES.HEALTHPLANS.level) === 0 && this.props.roleName !== 'SYSADMIN') || this.props.roleName === 'USER');
    let _title= (this.props.selectedHealthPlan?'Edición del Plan ':' Alta de Plan')
    + (this.props.selectedHealthPlan?this.props.selectedHealthPlan.healthPlanName:'');
    return (
      <Container {...this.props}>
        <div className='adminTitle'>{_title}</div>
        <Grid container spacing={16} style={{paddingLeft: 16, paddingRight: 16}}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Nombre'
              fullWidth={true}
              autoFocus={true}
              value={this.props.newHealthPlan.healthPlanName}
              onChange={(e) => this.props.actions.healthPlansEditor.updateField({attr:'healthPlanName', value:e.target.value})}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Precio'
              fullWidth={true}
              autoFocus={true}
              value={this.props.newHealthPlan.healthPlanPrice}
              onChange={(e) => this.props.actions.healthPlansEditor.updateField({attr:'healthPlanPrice', value:e.target.value})}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
        <Fab key='couponClose' size="small" color="secondary" aria-label="Discard" style={{position: 'fixed', top: '80px', right: '20px'}} onClick={() => this.props.history.goBack()}>
          <CloseIcon />
        </Fab>
        {
          readOnly ?
            null
          :
            <Fab key='couponSave' size="small" color="secondary" aria-label="Save" style={{position: 'fixed', bottom: '110px', right: '20px'}} onClick={() => this.props.actions.healthPlansEditor.saveState(this.props.history)}>
              <SaveIcon />
            </Fab>
        }
        {
          this.props.selectedHealthPlan && !readOnly ?
            <Fab key='couponDelete' size="small" color="secondary" aria-label="Delete" style={{position: 'fixed', bottom: '60px', right: '20px'}} onClick={() => this.props.actions.healthPlansEditor.deleteHealthPlan(this.props.history)}>
              <DeleteIcon />
            </Fab>
          :
            null
        }
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedHealthPlan: state.healthPlansList.selectedHealthPlan,
    newHealthPlan: state.healthPlansEditor,
    access: state.login.access
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      healthPlansEditor: bindActionCreators(HealthPlansEditorActions, dispatch),
    }
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(HealthPlanEditor);
