import { CITIES_EDITOR_UPDATE_FIELD, CITIES_LIST_SET_SELECTED_CITY} from '../constants/actions';

const _initCity={id: 0, cityName: "", cityStatus: "A"}

export default function citiesEditor(state = _initCity, action) {
  let _state= {...state};
  switch (action.type) {
    case CITIES_EDITOR_UPDATE_FIELD:
      _state[action.value.attr] = action.value.value;
      return _state;
    case CITIES_LIST_SET_SELECTED_CITY:
      return action.value?action.value:_initCity;
    default:
      return state;
  }
}
